.Summery {
    margin-top: -24px;
}

.Summery > p {
    color: var(--grey);
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
}

.Summery > p::before {
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: var(--light-grey);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}

.Wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.Wrapper > div {
    background: var(--white);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    transition: 250ms;
}

.Wrapper > div:hover {
    box-shadow: 6px 10px 20px var(--light-grey);
    transition: 250ms;
}

/* Active */
.Wrapper .activeBox {
    background: linear-gradient(90deg, var(--secondary) 0%, var(--secondary2) 100%);
    box-shadow: 0px 4px 8px var(--light-grey);
    padding: 0 20px;
}

.Wrapper > .activeBox > p {
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
}

.Wrapper > .activeBox > p > span {
    color: var(--white);
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.9;
    margin-top: -14px;
    padding-bottom: 4px;
}

.Wrapper .activeBox .bgIcon {
    position: absolute;
    opacity: 0.08;
    font-size: 4em;
    bottom: 8px;
    right: 0;
}

/* Not Active */
.Wrapper .box {
    background: var(--white);
    box-shadow: 0px 4px 8px var(--light-grey);
    padding: 0 18px;
}

.Wrapper > .box > p {
    color: var(--dark);
    font-size: 18px;
    font-weight: 500;
}

.Wrapper > .box > p > span {
    color: var(--dark);
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.9;
    margin-top: -14px;
    padding-bottom: 4px;
}

.Wrapper .box .bgIcon {
    position: absolute;
    opacity: 0.07;
    font-size: 3.8em;
    bottom: 8px;
    right: 0;
    color: var(--grey);
}

.Wrapper > div > p:nth-child(1) > span {
    font-size: 0.9em;
    font-weight: 500;
}

.Wrapper > div > p:nth-child(2) {
    font-size: 1em;
    margin: 0px 0;
    font-weight: 500;
    margin-top: -10px;
    margin-bottom: 10px;
}

.Graph {
    margin-top: 20px;
    background: var(--white);
    border-radius: 10px;
}

@media (max-width: 420px) {
    .Summery {
        margin-top: -32px;
    }

    .Summery > p {
        font-size: 12px;
        margin-bottom: 24px;
    }

    .Wrapper {
        grid-template-columns: 154px repeat(3, 116px);
        overflow-x: auto;
        margin-top: -20px;
        grid-gap: 10px;
    }

    .Wrapper > div {
        max-height: 55px;
    }

    .Wrapper .activeBox .bgIcon {
        position: absolute;
        opacity: 0.08;
        font-size: 2em;
        bottom: 14px;
        right: 4px;
    }

    .Wrapper .box .bgIcon {
        position: absolute;
        opacity: 0.07;
        font-size: 2em;
        bottom: 14px;
        right: 4px;
        color: var(--grey);
    }
    .Wrapper .activeBox {
        padding: 0 16px;
    }

    .Wrapper > .activeBox > p {
        color: var(--white);
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 12px;
        margin-top: 8px;
    }

    .Wrapper > .activeBox > p > span {
        color: var(--white);
        text-align: left;
        font-size: 10px;
        font-weight: 500;
        opacity: 0.9;
        margin-top: -16px;
        padding-bottom: 4px;
    }

    .Wrapper > .box {
        padding: 0 16px;
    }

    .Wrapper > .box > p {
        color: var(--dark);
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 12px;
        margin-top: 8px;
    }

    .Wrapper > .box > p > span {
        color: var(--dark);
        text-align: left;
        font-size: 10px;
        font-weight: 500;
        opacity: 0.9;
        margin-top: -14px;
        padding-bottom: 4px;
    }

    .Wrapper > div > p:nth-child(1) > span {
        font-size: 1.1em;
    }

    .Wrapper > div > p:nth-child(2) {
        font-size: 12px;
    }

    .Graph {
        margin-top: 10px;
    }
}
