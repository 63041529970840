.wrapper {
    margin-top: -16px;
}

.wrapper > p {
    color: var(--dark);
    font-size: 14px;
    margin-bottom: -10px;
    position: relative;
    margin-bottom: 10px;
}

.wrapper > p::before {
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: var(--grey2);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}

.family {
    margin-top: 20px;
    background-image: linear-gradient(#93a7c5, #21527d);
    border-radius: 10px;
    padding: 20px 30px;
    padding-bottom: 30px;
    margin: 0px 0;
}

.family > .Header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.family > .Header > form > input {
    color: var(--dark-blue);
    border: 1px solid var(--primary);
    border-radius: 4px;
    padding: 10px 20px;
    width: 50em;
    font-size: 12px;
    z-index: 100;
}

.family > .Header > form input:focus {
    outline: 0;
}

.family > .list > .optAll {
    position: relative;
    margin: 20px;
    background: var(--white);
    width: 80%;
    border-radius: 4px;
    margin: 0 auto;
    margin-bottom: -20px;
}

.family > .list > .optAll > .optSelect {
    margin-top: 40px;
    padding: 12px;
    border-radius: 10px;
    font-size: 12px;
    color: var(--secondary);
    border: 1px solid var(--light);
}

.family > .list > .optAll > .optSelect > div > button {
    background: var(--light);
    border: none;
    border-radius: 50px;
    font-size: 16px;
    color: var(--secondary);
    float: right;
    margin-right: 6px;
    margin-top: -2px;
}

.family > .list > .optAll > .optSelect > div > button:hover {
    color: var(--green);
    cursor: pointer;
}

.formPopup {
    margin-top: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: var(--white);
    /* padding: 14px 28px; */
    border-radius: 10px;
    max-width: 600px;
    min-width: 300px;
    z-index: 2;
}

.formPopup > .reqInfo {
    width: 500px;
    height: 500px;
    border-radius: 10px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    padding: 24px;
    border: 1px solid var(--light-grey);
}

.reqInfo > h2 {
    color: var(--dark);
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    margin-bottom: -20px;
    font-weight: 500;
    font-size: 16px;
}

.reqInfo .Content {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
}

.reqInfo > .Content form > label {
    color: var(--dark-blue);
    font-size: 12px;
    margin: 0 0 0 10px;
    position: relative;
    top: 10px;
    right: 130px;
    background: var(--white);
    padding: 0 2px;
}
.reqInfo > .Content form > input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid var(--secondary);
    outline: 0;
    display: block;
    margin: 0 0 10px 0;
    border-radius: 2px;
    padding: 7px 10px;
    width: 100%;
    font-size: 12px;
    color: var(--dark);
}
.reqInfo > .Content form > input:focus {
    outline: 0;
}
.reqInfo > .Content form > button {
    cursor: pointer;
    border: none;
    background: var(--secondary);
    color: var(--white);
    display: inline-block;
    padding: 8px 25px;
    border-radius: 4px;
    margin: 20px 30px;
    font-size: 12px;
}
.reqInfo > .Content form button:hover {
    opacity: 0.8;
    transition: 200ms;
    cursor: pointer;
}

.reqInfo > .Content form > button:last-child {
    background-color: var(--red);
}

@media (max-width: 420px) {
    .wrapper > p {
        font-size: 12px;
        margin-bottom: -10px;
        margin-bottom: 10px;
    }

    .family > .Header > form > input {
        color: var(--dark-blue);
        border: 1px solid var(--primary);
        border-radius: 4px;
        padding: 10px 20px;
        width: 100%;
        font-size: 12px;
        z-index: 100;
    }
}
