.list {
    margin-top: 2em;
    padding: 40px;
}

@media (max-width: 420px) {
    .list {
        padding: 20px;
    }

    .row td {
        display: flex;
        border: none;
        position: relative;
        border-bottom: 1px solid var(--light);
        font-size: 12px;
        justify-content: center;
        padding-left: 200px;
    }

    .row td:before {
        position: absolute;
        top: 10px;
        left: 26px;
        padding-right: 10px;
        white-space: nowrap;
        font-size: 12px;
    }

    .row td:nth-of-type(1):before {
        content: 'Serial';
    }
    .row td:nth-of-type(2):before {
        content: 'Doctor';
    }
    .row td:nth-of-type(3):before {
        content: 'Cause';
    }
    .row td:nth-of-type(4):before {
        content: 'Date';
    }
    .row td:nth-of-type(5):before {
        content: 'Remarks';
    }
    .row td:nth-of-type(6):before {
        content: 'Details';
    }
}
