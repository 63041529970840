.wrapper,
.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    left: 0;
    top: 0;
}

.overlay {
    background: rgb(0, 0, 0, 0.4);
    z-index: 10;
}

.formWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    padding: 20px 40px;
    transform: translate(-50%, -50%);
    background: var(--white);
    box-sizing: border-box;
    box-shadow: 1px 1px var(--light);
    border-radius: 10px;
    margin: 0 auto;
    z-index: 10;
}

.formWrapper .innerWrap {
    padding: 20px 30px;
    background: var(--light-low);
    border-radius: 6px;
    margin-bottom: 20px;
}

.formWrapper .section {
    color: var(--secondary);
    margin-top: 0px;
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.icon {
    position: absolute;
    z-index: 1000;
    font-size: 1.2em;
    color: var(--white);
    transform: rotate(45deg);
    right: 54px;
    margin-top: -14px;
    opacity: 0.8;
}

.section .leaf {
    position: absolute;
    color: var(--light-secondary);
    font-size: 12px;
    margin-top: -6px;
    margin-left: 12px;
}

.formWrapper label {
    display: block;
    font-size: 14px;
    color: var(--dark-grey);
    margin-bottom: 14px;
    font-weight: 450;
}

.formWrapper .select {
    background-color: var(--white);
    font-size: 14px;
    text-transform: capitalize;
}

.formWrapper .select option {
    font-size: 14px;
    text-transform: capitalize;
}

.formWrapper input[type='text'],
.formWrapper textarea,
.formWrapper .select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 2px solid var(--white);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.35);
    font-size: 14px;
    color: var(--dark-grey);
    outline: none;
}

.formWrapper input[type='text']:focus,
.formWrapper textarea:focus {
    border: 2px solid var(--grey2);
}

.formWrapper .button {
    background-color: var(--secondary);
    padding: 10px 20px;
    color: var(--white);
    font-size: 14px;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin: 0 auto;
    letter-spacing: 1px;
    border: none;
    border-radius: 4px;
    display: block;
    margin-top: 1em;
    cursor: pointer;
    font-weight: 500;
}

.fetchLabel {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: 1px 2px 4px var(--light-grey);
    margin-bottom: 20px;
}

.fetchLabel p {
    display: inline;
    position: relative;
    visibility: hidden;
}

.formGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.alertMessage {
    text-align: center;
    color: var(--red);
    font-weight: 500;
    font-size: 14px;
    margin-top: 16px;
}

.starSign {
    color: var(--red);
}

.cross {
    position: absolute;
    right: 20px;
    top: 12px;
    font-size: 18px;
    color: var(--red);
    font-weight: 500;
}

.cross:hover {
    opacity: 0.8;
    cursor: pointer;
    transition: 200ms;
}

@media (max-width: 420px) {
    .formGrid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 6px;
    }

    .wrapper {
        width: 95%;
        margin-left: 4px;
        margin-top: -30px;
    }
    .overlay {
        background: rgb(0, 0, 0, 0.2);
    }

    .formWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        padding: 20px 40px 30px 40px;
        transform: translate(-50%, -50%);
        background: var(--white);
        box-sizing: border-box;
        box-shadow: 1px 1px var(--light);
        border-radius: 10px;
        margin: 0 auto;
        z-index: 10;
    }

    .formWrapper form {
        margin: -22px;
    }

    .formWrapper .formMargin {
        margin-top: -28px;
    }

    .formWrapper .section {
        margin-top: 16px;
        font-size: 14px;
        margin-bottom: 2px;
    }

    .icon {
        font-size: 0.8em;
        right: 20px;
        margin-top: -22px;
    }

    .section .leaf {
        font-size: 10px;
        margin-top: -6px;
        margin-left: 10px;
    }

    .formWrapper .innerWrap {
        padding: 16px 14px 10px 14px;
        margin-bottom: 0px;
    }

    .formWrapper label {
        font-size: 12px;
    }

    .formGrid label:nth-child(2) {
        margin-top: -28px;
    }

    .formWrapper .select {
        font-size: 10px;
    }

    .formWrapper .select option {
        font-size: 10px;
    }

    .formWrapper input[type='text'],
    .formWrapper textarea,
    .formWrapper select {
        padding: 6px;
        font-size: 12px;
    }

    .fetchLabel {
        padding: 8px 10px;
        font-size: 12px;
    }

    .formWrapper .button {
        padding: 6px 10px;
        font-size: 10px;
        margin-top: 10px;
    }

    .alertMessage {
        font-size: 12px;
    }

    .cross {
        position: absolute;
        right: 14px;
        top: 8px;
        font-size: 16px;
        color: var(--red);
        font-weight: 500;
    }
}
