.wrapper {
    padding: 0 10px;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 20px;
}

.wrapper > p {
    color: var(--grey);
    font-size: 14px;
    margin-bottom: 0px;
    position: relative;
}

.wrapper > p::before {
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: var(--light-grey);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}

.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6px;
    margin-top: 10px;
}

.containerFlex {
    background-color: var(--white);
    border-radius: 10px;
    min-height: 132px;
    box-shadow: 0px 4px 6px var(--light-grey);
    position: relative;
    padding-bottom: 14px;
}

.containerFlex:hover {
    box-shadow: 0px 6px 14px var(--light-grey);
    cursor: pointer;
    transition: 250ms;
}

.containerFlex > div:nth-child(2) {
}

.containerFlex > div img {
    display: block;
    object-fit: cover;
    object-position: 48% 10%;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 140px;
    border-radius: 10px 0px 2px 2px;
}

.containerFlex .ratting {
    padding: 4px 10px;
    background-color: var(--grey);
    position: absolute;
    color: var(--white);
    border-radius: 4px;
    margin-top: -1.2em;
}

.containerFlex .ratting > p {
    color: var(--white);
    font-size: 10px;
    margin: 0;
}

.containerFlex > div {
    text-align: center;
    color: var(--dark);
}

.containerFlex > div > span {
    margin-left: 2px;
    font-size: 14px;
    margin-top: 20px;
    display: block;
    cursor: pointer;
}

.containerFlex > div > p {
    color: var(--dark);
    font-size: 14px;
    margin: 16px;
    text-align: center;
}

.containerFlex > div > div > p:nth-child(1) {
    font-size: 10px;
    margin-bottom: 8px;
    font-size: 12px;
    margin-left: 12px;
}

.containerFlex > div > div > p:nth-child(2) {
    margin-bottom: -10px;
    font-size: 12px;
    text-transform: capitalize;
    margin-left: 12px;
}

.containerFlex > div > p > span {
    font-size: 14px;
    color: var(--dark);
}

.containerFlex .ratting > p > svg {
    font-size: 10px;
    color: var(--orange);
}

.info > a {
    display: block;
    margin-top: 12px;
}

/* for responsive part  */

.containerFlex > div img {
    display: block;
    object-fit: cover;
    position: relative;
    overflow: hidden;
    width: 67px;
    height: 67px;
    border-radius: 10px 2px 2px 2px;
}

.containerFlex > div > span {
    font-size: 12px;
    margin-top: 8px;
    font-weight: 500;
}

.containerFlex > div:nth-child(1) {
    /* border: 2px solid var(--red); */
}

.containerFlex > div > p {
    font-size: 14px;
}

.containerFlex > .info > a > span {
    font-size: 15px;
    margin-top: 28px;
    display: block;
    /* margin-bottom: 10px; */
    margin-left: 0px;
    margin-right: 0%;
}

.containerFlex > .info > p:last-child {
    position: absolute;
    top: -10px;
    left: 30%;
}

.containerFlex > .info > p:last-child > span {
    font-weight: 500;
}

.containerFlex > div > div {
    position: absolute;
    top: 14px;
    left: 39%;
    text-align: left;
    font-size: 8px;
}

.containerFlex > div > div > p:nth-child(1) {
    font-size: 12px;
    margin-top: 14px;
    margin-bottom: -6px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 0px;
    /* border: 1px solid var(--red); */
}

.containerFlex > div > div > p:nth-child(2) {
    margin-bottom: -8px;
    font-size: 12px;
    font-weight: 500;
    margin-left: 0px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containerFlex .ratting {
    padding: 2px 6px;
    margin-top: -12px;
}

.containerFlex .ratting > p {
    color: var(--white);
    font-size: 8px;
    margin: 0;
}

.containerFlex .ratting > p > svg {
    font-size: 8px;
    color: var(--orange);
}

.currency {
    color: var(--primary);
}

.button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.img1 {
    height: 29px;
    width: 29px;
    cursor: pointer;
}

.info {
}

/* .doctorName {
    border: 1px solid var(--red);
} */
@media (max-width: 420px) {
    .wrapper {
        padding: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .wrapper > p {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .container {
        position: relative;
        grid-template-columns: repeat(4, 200px);
        grid-gap: 14px;
        margin-top: 10px;
        overflow-x: auto;
    }

    .containerFlex {
        min-height: 100px;
        padding-bottom: 0px;
    }

    .containerFlex > div img {
        display: block;
        object-fit: cover;
        position: relative;
        overflow: hidden;
        width: 64px;
        height: 64px;
        border-radius: 10px 2px 2px 2px;
    }

    .containerFlex > div > span {
        font-size: 12px;
        margin-top: 8px;
        font-weight: 500;
    }

    .containerFlex > div > p {
        font-size: 10px;
    }

    .containerFlex > .info > a > span {
        font-size: 14px;
        margin-top: 24px;
        display: block;
        margin-bottom: 14px;
        margin-left: 0px;
        margin-right: 0%;
    }

    .containerFlex > .info > p:last-child {
        position: absolute;
        top: -10px;
        left: 30%;
    }

    .containerFlex > .info > p:last-child > span {
        font-weight: 500;
    }

    .containerFlex > div > div {
        position: absolute;
        top: 14px;
        left: 39%;
        text-align: left;
        font-size: 8px;
    }

    .containerFlex > div > div > p:nth-child(1) {
        font-size: 10px;
        margin-top: 14px;
        margin-bottom: -6px;

        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 0px;
    }

    .containerFlex > div > div > p:nth-child(2) {
        margin-bottom: -10px;
        font-size: 10px;
        font-weight: 500;
        margin-left: 0px;
    }

    .containerFlex .ratting {
        padding: 2px 6px;
        margin-top: -12px;
    }

    .containerFlex .ratting > p {
        color: var(--white);
        font-size: 8px;
        margin: 0;
    }

    .containerFlex .ratting > p > svg {
        font-size: 8px;
        color: var(--orange);
    }

    .containerFlex > div > p {
        font-size: 10px;
    }
}
