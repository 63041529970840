.medWrapper {
    width: 100%;
}

.medWrapper:nth-child(1) > div > label > span {
    display: inline-block;
}

.medWrapper > div > label > span {
    display: none;
}

.medWrapper .formGrid {
    display: grid;
    grid-template-columns: 1.5fr repeat(5, 1fr) 0.2fr;
    padding-right: 18px;
}

.medWrapper label {
    display: block;
    font-size: 12px;
    color: var(--dark-grey);
    margin-bottom: 2px;
    font-weight: 450;
    margin-top: 10px;
    text-align: center;
}

.medWrapper label:hover {
    background-color: none;
}

.medWrapper .select {
    background-color: var(--white);
    font-size: 14px;
}

.medWrapper .select option {
    font-size: 12px;
}

.medWrapper input[type='text'],
.medWrapper input[type='number'],
.medWrapper textarea,
.medWrapper select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 2px solid var(--white);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.35);
    font-size: 12px;
    color: var(--dark-grey);
    outline: none;
    cursor: pointer;
    text-align: center;
}

.medWrapper input[type='text']:focus,
.medWrapper input[type='number']:focus,
.medWrapper textarea:focus {
    border: 2px solid var(--grey2);
}

.medWrapper .fetchLabel {
    display: block;
    width: 100%;
    padding: 7.8px;
    font-size: 12px;
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: 1px 2px 4px var(--light-grey);
    border: 1px solid var(--light);
    margin-bottom: 20px;
}

.medWrapper .fetchLabel p {
    display: inline;
    position: relative;
    visibility: hidden;
}

.medWrapper .optAll {
    position: absolute;
    margin-top: 4.6em;
    background-color: var(--light-low);
    width: 40%;
    border-radius: 4px;
    padding: 2px 4px;
    overflow-y: auto;
}

.medWrapper .optSelect {
    box-shadow: 1px 2px 4px var(--light-grey);
    padding: 10px 6px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
}

.medWrapper .optSelect:hover {
    background-color: var(--secondary);
    color: var(--white);
}

.medWrapper .refMargin {
    margin-bottom: 3em;
}

.medWrapper .cross {
    background-color: transparent;
    border: none;
    font-size: 1em;
    color: var(--red);
    margin-left: 10px;
    margin-top: -1em;
}

.medWrapper .cross:hover {
    cursor: pointer;
    opacity: 0.8;
}

@media (max-width: 420px) {
    .medWrapper {
        padding-bottom: 10px;
    }
    .medWrapper > div > label > span {
        display: block;
    }

    .medWrapper .formGrid {
        grid-template-columns: 1.2fr 1fr 1.2fr;
        grid-gap: 0;
        padding-right: 0px;
        position: relative;
        border-bottom: 1px solid var(--grey2);
        margin-bottom: 20px;
        padding-bottom: 10px;
    }

    .medWrapper label {
        margin-top: 0px;
    }

    .medWrapper .cross {
        position: absolute;
        bottom: 0;
        margin-left: 44%;
    }

    .medWrapper .optAll {
        position: absolute;
        margin-top: 4.2em;
        background-color: var(--light-low);
        width: 100%;
        border-radius: 4px;
        padding: 4px 6px;
        overflow: auto;
        background-color: var(--white);
        z-index: 10;
    }
}
