.DoctorList {
    width: 100%;
    margin-bottom: 1em;
    margin-top: 40px;
}

.doctor {
    display: grid;
    grid-template-columns: minmax(0, 18%) minmax(0, 35%) minmax(0, 27%) minmax(0, 20%);
    margin: 6px auto;
    width: 100%;
    background-color: var(--white);
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 2px 4px 8px var(--light);
}

.doctor:hover {
    box-shadow: 4px 8px 10px var(--light);
    transition: 200ms;
}

.doctorPic {
    width: 110px;
    height: 112px;
    object-fit: cover;
    object-position: 48% 16%;
    border-radius: 4px;
    transition: 250ms;
}

.doctorPic:hover {
    transform: scale(1.1);
    cursor: pointer;
    transition: 250ms;
    border-radius: 10px;
}

.nameContainer {
    margin-left: -2em;
    margin-top: -4px;
}

.name {
    color: var(--primary);
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 4px;
}

.name:hover {
    opacity: 0.8;
}

.qualification {
    color: var(--grey);
    margin: 0;
    font-size: 12px;
}
.speciality {
    color: var(--dark);
    font-size: 12px;
    margin: 0;
    text-transform: capitalize;
    margin-top: 4px;
}
.designation {
    font-size: 12px;
    margin: 10px 0 0 0;
    /* visibility: hidden; */
}
.currentWorkPlace {
    font-size: 12px;
    margin: 2px 0 0 0;
    color: var(--dark);
}

.doctor > div:nth-child(3) {
    margin-top: 0.5em;
    text-align: center;
    margin-left: -2em;
}
.doctor > div:nth-child(2) {
    margin-top: 1px;
    /* margin-top: 1em;
    text-align: center;
    margin-left: -2em; */
}

.doctor > div:nth-child(3) > p {
    color: var(--dark);
    font-size: 12px;
}

.doctor > div:nth-child(3) > p {
    color: var(--dark);
    font-size: 12px;
}

.doctor > div:nth-child(2) > p {
    font-size: 14px;
}

.doctor > div:nth-child(3) > span {
    color: var(--dark);
    font-size: 12px;
    margin-top: -10px;
    display: block;
}

.doctor > div:nth-child(4) {
    margin-top: 0em;
}

.doctor > div:nth-child(4) > p {
    font-size: 12px;
    line-height: 35px;
    font-weight: 500;
    color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 3px;
    display: block;
    width: 100%;
    height: 40px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
}

.doctor > div:nth-child(4) > button {
    font-size: 12px;
    font-weight: 500;
    color: var(--white);
    border: 3px solid var(--secondary);
    background: var(--secondary);
    border-radius: 3px;
    display: block;
    width: 100%;
    height: 40px;
    text-align: center;
    cursor: pointer;
}

.doctor > div:nth-child(4) > button:hover {
    opacity: 0.9;
    transition: 200ms;
}

.doctor > div:nth-child(4) > button > a {
    color: var(--white);
}

/* additional  */
.bmdc {
    color: var(--dark);
    font-size: 14px;
    margin-left: 10px;
}

.fees > p {
    margin: 0;
    color: var(--primary);
    font-weight: 500;
    font-size: 22px;
    margin-bottom: -2px;
}

.fees > p > span {
    color: var(--secondary);
    font-size: 10px;
}

.fees > span {
    color: var(--dark);
    font-size: 14px;
    display: block;
}

@media (max-width: 420px) {
    .doctor {
        grid-template-columns: repeat(1, minmax(0, 100%));
        margin: 20px auto;
        width: 100%;
        border-bottom: 1px solid var(--light);
        text-align: center;
    }

    .doctorPic {
        width: 110px;
        height: 110px;
        margin: 0 auto;
    }

    .nameContainer {
        margin-left: 0px;
    }

    .name {
        color: var(--primary);
        margin: 12px 0 0 0;
        font-size: 18px;
        font-weight: 500;
    }

    .qualification {
        color: var(--dark);
        margin: 0;
        font-size: 12px;
        opacity: 0.9;
    }

    .speciality {
        color: var(--dark);
        font-size: 12px;
        font-weight: 600;
        margin-top: 10px;
        opacity: 0.8;
    }

    .designation {
        font-size: 14px;
        margin: 30px 0 0 0;
        color: var(--dark);
    }

    .currentWorkPlace {
        font-size: 12px;
        margin: 4px 0 10px 0;
        color: var(--secondary);
        font-weight: 500;
    }

    .doctor > div:nth-child(2) {
        margin-left: 0;
    }

    .doctor > div:nth-child(3) {
        margin-left: 0;
    }

    .doctor > div:nth-child(3) > p {
        margin-top: -10px;
    }

    .experience {
        margin-top: 4px !important;
    }
    .speciality {
        margin-bottom: -15px;
    }
}
