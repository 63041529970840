.loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--white);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
}

.loading > img {
    display: block;
    margin: 0 auto;
    width: 30%;
}

@media (max-width: 420px) {
    .loading > img {
        width: 70%;
    }
}
