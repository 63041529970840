.Text {
    background: var(--white);
    border-radius: 10px;
    padding: 20px 40px;
    margin: 20px 0;
}
.Text h3 {
    color: var(--primary);
    margin: 5px 5px 10px 5px;
}
.Text h4 {
    margin: 5px;
    color: var(--dark-blue);
}

.Text form input {
    background: var(--light);
    border: none;
    padding: 10px;
    border-radius: 4px 0 0 4px;
}
.Text form input:focus {
    outline: 0;
}
.Text form button {
    border: none;
    outline: 0;
    color: var(--white);
    background: var(--primary);
    padding: 10px 15px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.tlist {
    margin: 20px 0 0 5px;
}
.tlist > p {
    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0 0 0;
    display: block;
}
.tlist > p > span {
    margin: 0 0 0 15px;
    display: none;
    color: var(--white);
    background: var(--red);
    border-radius: 50px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    transition: 300ms;
}
.tlist > p:hover > span {
    display: inline-block;
}
