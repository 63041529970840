* {
    box-sizing: border-box;
}

.clear:after {
    content: '';
    display: table;
    clear: both;
}

.bodyPdf {
    position: relative;
    width: 100%;
    height: 100%;
    color: var(--dark);
    background-color: var(--light);
    padding: 6px 0px;
}

.bodyPdf .page {
    width: 210mm;
    height: 294mm;
    margin: 0 auto;
    background-color: var(--white);
    padding: 2em;
    border-radius: 2px;
    font-size: 12px;
    position: relative;
}

@page {
    size: A4;
    margin: 0;
}

header {
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--grey);
}

a {
    color: var(--primary);
    text-decoration: none;
}

.logo {
    float: left;
    margin-top: 10px;
}

.logo img {
    height: 26px;
}

.company {
    float: right;
    text-align: right;
}

.details {
    margin-bottom: 30px;
}

.client {
    padding-left: 6px;
    border-left: 6px solid var(--secondary);
    float: left;
}

.client .to {
    color: var(--dark-grey);
}

.name {
    font-size: 1.2em;
    font-weight: normal;
}

.invoice {
    float: right;
    text-align: right;
}

.invoice .name {
    color: var(--dark);
    font-size: 1.2em;
    line-height: 1em;
    font-weight: normal;
    margin: 0 0 10px 0;
}

.invoice .date {
    font-size: 1.1em;
    color: var(--dark-grey);
}

.bodyPdf .tablePdf {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0px auto;
}

.bodyPdf .tablePdf th,
.bodyPdf .tablePdf td {
    padding: 20px;
    background-color: var(--light);
    text-align: center;
    border-bottom: 1px solid var(--white);
    color: var(--dark);
}

.bodyPdf .tablePdf thead th {
    background-color: var(--light);
    color: var(--dark);
}

.bodyPdf .tablePdf thead:nth-child(2) th {
    display: none;
}

.bodyPdf .tablePdf th {
    white-space: nowrap;
    font-weight: 600;
}

.bodyPdf .tablePdf td {
    text-align: right;
    padding: 10px;
}

.bodyPdf .tablePdf td.no,
.bodyPdf .tablePdf td.desc,
.bodyPdf .tablePdf td.unit,
.bodyPdf .tablePdf td.qty,
.bodyPdf .tablePdf td.total {
    font-size: 1em;
    text-align: center;
}

.bodyPdf .tablePdf .desc,
.bodyPdf .tablePdf .qty {
    background: var(--light);
}

.bodyPdf .tablePdf tbody tr:last-child td {
    border: none;
}

.amountFlex {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
}

.amount {
    float: right;
    text-align: end;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 14px;
    width: 50%;
}

.amount tr:nth-child(2) td {
    border-bottom: 1px solid var(--grey);
    margin-left: 20px;
}

.amountWord {
    margin: 4em auto;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.amountWord > div > span {
    font-weight: 400;
}

.text {
    text-align: center;
    font-size: 1em;
    margin-bottom: 40px;
}

.text > p {
    margin-bottom: 0px;
}

.notices {
    margin-top: 12px;
    padding-left: 6px;
    border-left: 6px solid var(--secondary);
}

.notices .notice {
    font-size: 1.2em;
    text-transform: capitalize;
    color: var(--dark);
    font-weight: 500;
    font-size: 14px;
}

.note {
    margin-top: 1.2em;
    font-style: italic;
}

.bgLogo {
    position: absolute;
    display: flex;
    justify-content: center;
    opacity: 0.03;
    margin-left: 16em;
    margin-top: 2em;
}

.bgLogo > img {
    width: 30em;
}

.arrow {
    width: 96%;
    margin: 0 auto;
    height: 50px;
    position: relative;
}

.arrow p {
    text-align: center;
    font-size: 2em;
    font-weight: 400;
    color: var(--dark-grey);
    border: 1px solid var(--grey2);
}

.boxAll {
    position: absolute;
    width: 96%;
    bottom: 4rem;
    left: 2%;
}

.service {
    display: grid;
    grid-template-columns: 0.7fr 0.8fr 0.1fr 1fr 0.1fr 1.6fr 0.1fr 1fr;
    text-align: center;
    font-size: 12px;
}

.service .icon {
    margin: 0 4px;
}

.devlop {
    position: absolute;
    text-align: center;
    font-style: italic;
    bottom: 20px;
    right: 20px;
    font-size: 8px;
}
