.Hero {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));
    background-position: center;
    background-size: cover;
    box-shadow: 0px 1px 50px var(--light);
}
.Hero::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--white);
    opacity: 0.7;
}
.left {
    padding: 120px 0 150px 0;
    position: relative;
    z-index: 100;
}
.left > h2 {
    margin-left: 15vw;
    font-size: 32px;
    color: var(--dark);
    text-align: center;
}
.left > h2 > span {
    color: var(--primary);
}

.right > img {
    position: absolute;
    right: 15vw;
    height: 100%;
}

.text {
    margin-top: -10px;
    margin-left: 15vw;
    text-align: center;
}
.text span {
    display: inline-block;
    color: var(--dark);
    font-size: 18px;
}
.ButtonGroup span:nth-child(1) {
    border-radius: 10px 0 0 10px;
}
.ButtonGroup span:nth-child(2) {
    border-radius: 0 10px 10px 0;
    color: var(--primary);
    background: transparent;
}

@media (max-width: 420px) {
    .Hero {
        grid-template-columns: repeat(1, minmax(0, 100%));
        margin-left: -12px;
    }
    .Hero::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: var(--white);
        opacity: 0.7;
    }
    .left {
        margin-left: -30px;
        margin-top: -20px;
    }

    .left > h2 {
        font-size: 18px;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .text {
        padding-right: 10px;
    }

    .text > span {
        font-size: 14px;
    }

    .right {
        display: flex;
        justify-content: center;
    }

    .right > img {
        right: 0vw;
        height: 66%;
        position: relative;
        margin-top: -10em;
    }
    .ButtonGroup span {
        padding: 4px 10px;
        min-width: 40px;
        font-size: 10px;
    }
    .ButtonGroup span:nth-child(1) {
        border-radius: 10px 0 0 10px;
    }
    .ButtonGroup span:nth-child(2) {
        border-radius: 0 10px 10px 0;
        color: var(--primary);
        background: transparent;
    }
}
