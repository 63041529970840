.medicine {
    margin-top: 24px;
}

.medicine > p {
    color: var(--dark);
    font-size: 14px;
    margin-bottom: 0px;
    position: relative;
}

.medicine > p::before {
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: var(--grey2);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}
