.container {
    position: fixed;
    bottom: 10px;
    background-image: linear-gradient(#93a7c5, #21527d);
    width: 22%;
    border-radius: 10px;
    padding: 10px;
}

.container > p {
    color: var(--light);
    font-size: 12px;
    margin-top: 0em;
    margin-bottom: 8px;
    position: relative;
}
.container > p::before {
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: var(--light-grey);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}

.flexWrapper {
    padding: 2px 20px;
    display: flex;
    align-items: center;
}

.flexWrapper > div > img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
}

.flexWrapper > div:nth-child(1) > p {
    width: 42px;
    margin-left: 4px;
    background-color: var(--white);
    margin-top: -12px;
    text-align: center;
    position: absolute;
    border-radius: 20px;
    font-size: 10px;
}

.flexWrapper > div:nth-child(2) > p {
    font-size: 14px;
    margin-left: 10px;
    margin-top: -4px;
    color: var(--white);
    font-weight: 500;
    margin-bottom: -2px;
}

.flexWrapper > div:nth-child(2) > span {
    font-size: 12px;
    padding-left: 10px;
    color: var(--dark);
}
