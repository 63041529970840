.containerPdf,
.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    left: 0;
    top: 0;
}

.overlay {
    background: rgb(0, 0, 0, 0.5);
    z-index: 1;
}

.formPdf {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80em;
    padding: 20px;
    transform: translate(-50%, -50%);
    background: var(--light);
    box-sizing: border-box;
    box-shadow: 1px 1px var(--light);
    border-radius: 10px 0px 0px 10px;
    margin: 0 auto;
    height: 90%;
    overflow-y: auto;
    z-index: 10;
}

.formPdf .close {
    position: absolute;
    top: 10px;
    right: 20px;
    transition: all 200ms;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: var(--red);
    cursor: pointer;
}
.formPdf .close:hover {
    opacity: 0.8;
    transition: 0.5s;
}

.body {
    box-sizing: border-box;
    background-color: var(--light);
}

.bodyPdf > .buttonPdf {
    display: block;
    margin: 0 auto;
    padding: 8px 20px;
    border-radius: 2px;
    font-size: 10px;
    border: none;
    color: var(--white);
    background-color: var(--secondary);
    cursor: pointer;
}

.buttonClose {
    padding: 4px 20px;
    color: var(--white);
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px auto;
    display: block;
}

.buttonClose {
    background-color: var(--red);
}

.buttonClose:hover {
    opacity: 0.8;
}

@media (max-width: 420px) {
    .formPdf {
        width: 18em;
        height: 80%;
    }

    .overlay {
        background: rgb(0, 0, 0, 0.2);
    }
}
