.wrapper {
    margin-top: 30px;
}

.wrapper > p {
    color: var(--dark);
    font-size: 14px;
    margin-bottom: -10px;
    position: relative;
}

.wrapper > p::before {
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: var(--grey2);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}

.Address {
    background: var(--white);
    border-radius: 10px;
    padding: 10px 20px;
    margin: 20px 0;
}

.Address > p {
    font-size: 12px;
    position: relative;
    color: var(--dark);
}

.Address > p > span {
    position: absolute;
    left: 120px;
    color: var(--dark-grey);
}
.Address > p > span::before {
    content: ': ';
}

.Address > p:nth-child(1) {
    font-size: 14px;
}
.Address > p:nth-child(2) {
    font-size: 14px;
}

@media (max-width: 420px) {
    .wrapper > p {
        font-size: 12px;
        margin-bottom: -10px;
    }

    .Address > p {
        font-size: 12px;
        position: relative;
        font-weight: 500;
        color: var(--dark-blue);
    }
    .Address > p:nth-child(1) {
        font-size: 14px;
        font-weight: 600;
    }
    .Address > p:nth-child(2) {
        font-size: 14px;
        font-weight: 500;
    }
}
