.Register {
    min-height: 100vh;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat;
}
.Register::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(231, 226, 250, 0.8);
    z-index: 10;
}
.statusMsg {
    position: absolute;
    top: 0;
    color: var(--white);
    background: var(--red);
    padding: 10px 12px;
    border-radius: 4px;
    font-size: 14px;
    z-index: 200;
    box-shadow: 0 5px 10px var(--grey);
}
.statusMsg > span {
    background: red;
    border-radius: 50px;
    display: inline-block;
    width: 14px;
    height: 14px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    margin: 0 0 0 4px;
}

.logo {
    position: absolute;
    top: 30px;
    left: 34px;
}

.logo > img {
    height: 48px;
    width: 48px;
    transition: 300ms;
}

.logo > img:hover {
    cursor: pointer;
    opacity: 0.9;
    transform: scale(1.1);
    transition: 300ms;
}

.Wrapper {
    display: grid;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    gap: 40px;
    background: var(--white);
    padding: 0;
    box-shadow: 0 20px 30px var(--grey);
    border-radius: 5px;
    width: 1000px;
    margin: 0 auto;
    z-index: 50;
    transition: 200ms;
}

.left {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 60px 50px;
}
.left > div {
    width: 100%;
}
.left > div > h2 {
    text-align: center;
    color: var(--primary);
    margin: 0 0 20px 0;
}
.left > div > h2 > svg {
    margin: 0 5px 0 0;
}
.left > div > form > div,
.left > div > form > div > div {
    position: relative;
    width: 100%;
}
.left > div > form > div > input,
.left > div > form > div > div > input {
    display: block;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    padding: 7px 0;
    margin: 0;
    box-sizing: border-box;
    color: var(--dark-blue);
    border-bottom: 3px solid var(--grey2);
    position: relative;
}
.left > div > form > div > input + label::before,
.left > div > form > div > div > input + label::before {
    content: '';
    position: absolute;
    bottom: 23px;
    left: 0;
    width: 0;
    height: 3px;
    background: var(--primary);
    transition: 200ms;
}
.left > div > form > div > input:focus + label::before,
.left > div > form > div > div > input:focus + label::before {
    width: 100%;
}
.left > div > form > div > input + label span,
.left > div > form > div > div > input + label span {
    font-size: 14px;
    color: var(--grey);
    position: relative;
    top: -30px;
    pointer-events: none;
    transition: 200ms;
}
.left > div > form > div > input:focus + label span,
.left > div > form > div > input:valid + label span,
.left > div > form > div > div > input:focus + label span,
.left > div > form > div > div > input:valid + label span {
    top: -52px;
    font-size: 12px;
}
.left > div > form > button {
    color: var(--white);
    background: var(--primary);
    display: block;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: 0;
    border-radius: 2px;
    padding: 7px 20px;
    cursor: pointer;
    margin: 10px 0 0 0;
}

.gridTwo {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));
    gap: 20px;
}
.gridTwo > input {
    box-sizing: border-box;
    width: 100%;
}

.gridTwo > div > select {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: none;
    color: var(--dark-blue);
    padding: 0 5px 0 5px;
    min-height: 30px;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    cursor: pointer;
    padding: 10px;
    outline: none;
}

.gridTwo > div > label,
.gridTwo > .nid > .nidLabel > span {
    color: var(--grey);
    font-size: 14px;
    margin: 0 0 0 0px;
}

.gridTwo > .nid {
    display: block;
    margin-top: 24px;
}

.Address {
    padding: 0 0 20px 0;
}
.Address h3 {
    font-weight: 400;
    color: var(--grey);
    margin: 5px 0;
}
.Address > div {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));
    gap: 20px;
}
.Address > div > select {
    background: var(--light);
    color: var(--dark-blue);
    border: none;
    padding: 10px;
}
.Address > div > select:focus {
    outline: 0;
}

.sexWrapper {
    display: grid;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    gap: 20px;
    margin: -12px 0 -12px 0px;
}
.sexWrapper > div > input,
.sexWrapper > div > select {
    display: block;
    width: 100%;
    box-sizing: border-box;
    /* background: var(--light); */
    border: none;
    color: var(--dark-blue);
    padding: 0 5px 0 5px;
    min-height: 30px;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    cursor: pointer;
}
.sexWrapper > div > select {
    padding: 10px;
}
.sexWrapper > div > input:focus,
.sexWrapper > div > select:focus {
    outline: 0;
}
.sexWrapper > div > select + span {
    width: 0;
    height: 0;
    position: relative;
    top: -22%;
    left: 90%;
    border-top: 5px solid var(--grey);
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    z-index: 100;
    pointer-events: none;
}
.sexWrapper > div > label {
    color: var(--grey);
    font-size: 14px;
    margin: 0 0 0 0px;
}
.linkText {
    color: var(--dark-blue);
    font-size: 12px;
    margin: 20px 0 0 0;
}
.linkText a {
    text-decoration: none;
    color: var(--primary);
    font-size: 18px;
    font-weight: 500;
    padding-left: 8px;
}

.right {
    font-size: 14px;
    color: var(--white);
    background: rgb(69, 125, 195, 0.8);
    border-radius: 0 5px 5px 0;
    padding: 20px 40px;
    text-align: center;
}

.right > div > h2 {
    margin-top: 8em;
}

.right > div > h2 > svg {
    font-size: 32px;
}
.right p {
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
}

.right > div > div {
    margin-top: 4em;
    text-align: center;
}

.right span {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    margin-top: 0px;
}

.right a {
    text-decoration: none;
    color: var(--white);
    background-color: var(--primary);
    padding: 10px 30px;
    border-radius: 4px;
    font-size: 18px;
}

.right a:hover {
    opacity: 0.9;
    transition: 200ms;
}

@media (max-width: 420px) {
    .right {
        display: none;
    }

    .logo {
        top: 20px;
        left: 24px;
    }

    .logo > img {
        height: 40px;
        width: 40px;
    }

    .Wrapper {
        grid-template-columns: 90%;
        gap: 0px;
    }
    .left {
        margin: 30px 0 60px 32px;
    }
    .left > div > h2 {
        margin: 10px 0 10px 0;
    }
    .Address h3 {
        margin-top: -2px;
    }
    .Address > div > select {
        padding: 10px;
        background-color: var(--white);
        border: 2px solid var(--light);
        border-radius: 2px;
    }
    .sexWrapper > div > select {
        padding: 10px;
        background-color: var(--white);
        border: 2px solid var(--light);
        border-radius: 2px;
    }
    .sexWrapper > div > select + span {
        top: -18%;
        left: 88%;
    }
    .sexWrapper > div > input[type='date'] {
        font-size: 12px;
        padding: 8px 2px;
        background-color: var(--white);
    }
}
