.profileCard {
    background: transparent;
    padding: 10px 0 0px;
}

.profileCard > div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileCard > div:nth-child(1) > span {
    padding: 0 4px;
    font-size: 12px;
    color: var(--dark);
}

.profileCard > div:nth-child(1) > span:nth-child(1) {
}

.profileCard > div:nth-child(1) > span:nth-child(2) {
    color: var(--primary);
}

.profileCard > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    text-align: center;
    padding: 10px 50px 10px 60px;
    margin-top: 0px;
    align-items: center;
}

.profileCard > div:nth-child(2) > div > span {
    color: var(--dark);
    font-size: 14px;
    font-weight: 500;
}

.profileCard > div:nth-child(2) > div > p {
    color: var(--dark);
    margin-top: 2px;
}
