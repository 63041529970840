.meeting {
    background-color: rgb(28, 26, 26);
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    /* justify-content: center; */
    position: relative;
}

.box {
    text-align: center;
}

.box > h3 {
    position: absolute;
    color: white;
    bottom: 18px;
    left: 10px;
    z-index: 10;
    font-size: 16px;
}

.view {
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
}

.view > div:first-child {
    width: 22%;
    background-color: rgb(41, 47, 48);
    min-height: 100vh;
    max-width: 100%;
    margin-top: -10vh;
    padding: 1em;
    padding-top: 5%;
}

.view > div:last-child {
    width: 78%;
    align-self: center;
    margin: 0 auto;
    margin-top: -6em;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

.flex > p {
    color: white;
    font-size: 1.2em;
}

.flex > button {
    background-color: rgb(82, 189, 224);
    border: none;
    padding: 10px 40px;
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgb(69, 69, 69);
    font-size: 1.4em;
}

.flex > button:hover {
    cursor: pointer;
    opacity: 0.9;
    transition: 250ms;
    box-shadow: 4px 8px 14px rgb(69, 69, 69);
}
