.wrapper {
    margin-top: 40px;
}

.wrapper > p {
    color: var(--grey);
    font-size: 14px;
    margin-bottom: 0px;
    position: relative;
}

.wrapper > p::before {
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: var(--light-grey);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}

.tableWrapper {
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: var(--white);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
}

.tableWrapper tr {
    box-shadow: 0 2px 4px var(--light);
    border-radius: 10px;
    border: 1px solid var(--light);
    padding: 20px;
}

.tableWrapper tr:hover {
    box-shadow: 4px 8px 12px var(--light);
    cursor: pointer;
}

.tableWrapper tr > td {
    font-size: 12px;
    padding: 10px;
    color: var(--dark);
    border-radius: 10px;
}

.tableWrapper tr > td > .icon {
    font-size: 20px;
    color: var(--secondary2);
}

.tableWrapper tr > td > span {
    margin-left: 12px;
}

.tableWrapper tr > td > span:hover {
    color: var(--secondary);
}

.tableWrapper tr > td:nth-child(2) {
    text-align: left;
}

.tableWrapper tr > td:nth-child(3) {
    text-align: center;
}

.tableWrapper tr > td:last-child {
    text-align: right;
}

.tableWrapper tr > td > button {
    border-radius: 4px;
    border: none;
    padding: 4px 10px;
    margin: 10px 0;
    background-color: var(--secondary2);
    color: var(--white);
    font-size: 10px;
}

.tableWrapper tr > td > button:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 200ms;
}

@media (max-width: 420px) {
    .wrapper {
        margin-top: 20px;
    }

    .wrapper > p {
        color: var(--grey);
        font-size: 12px;
        margin-bottom: 4px;
        position: relative;
    }

    .wrapper > div {
        overflow-x: auto;
    }

    .tableWrapper {
        display: table;
        border-spacing: 0px 4px;
        width: 600px;
        background-color: transparent;
        padding: 0;
        margin: 0;
    }

    .tableWrapper tr {
        background-color: var(--white);
        border: none;
        box-shadow: none;
    }

    .tableWrapper tr > td {
        font-size: 12px;
        padding: 10px;
        color: var(--dark);
        border-radius: 0px;
    }
}
