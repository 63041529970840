.UploadedReports {
    margin-top: 20px;
}

.UploadedReports > p {
    color: var(--primary);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
}

.UploadedReports > p::before {
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: var(--light-grey);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}

.container {
    background: var(--white);
    padding: 20px 40px 30px 40px;
    margin: 0 0 20px 0;
    border-radius: 10px;
}

.container > div:last-child {
    margin-top: 4em;
}

.UploadedReports .Header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}
.UploadedReports .Header p {
    font-size: 1.4em;
    margin-top: 6px;
}

.files {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
}

.files > div {
    text-align: center;
    box-shadow: 4px 6px 10px var(--light);
    border: 1px solid var(--light);
    padding: 20px 10px;
    border-radius: 10px;
    cursor: pointer;
}

.files > div:hover {
    box-shadow: 4px 10px 16px var(--light);
}

.files > div img {
    object-fit: cover;
    height: 8em;
    width: 8em;
    border-radius: 2px;
    transition: 250ms;
}

.files > div img:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: 250ms;
}

.files > div p {
    font-size: 12px;
    color: var(--dark);
    letter-spacing: 0.8px;
}

.files > div p:nth-child(3) {
    margin-top: -8px;
    font-size: 10px;
}

.files > div p > span {
    text-transform: capitalize;
}

.files > div > a {
    text-align: center;
    text-decoration: none;
}
.files > div > a > img {
    width: 4.8em;
    height: 4.8em;
}

.files > div > a > p {
    font-size: 12px;
    color: var(--dark);
    letter-spacing: 0.8px;
}

.files > div > a > p:nth-child(3) {
    margin-top: -8px;
    font-size: 10px;
}

.previewContainer,
.overlay {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 120vh;
    z-index: 10;
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
}

.previewContainer > .Preview {
    position: relative;
    top: 2%;
    left: 20%;
    width: 60%;
    height: 94vh;
    padding: 10px;
    background: var(--white);
    color: var(--dark-blue);
    position: relative;
    z-index: 10;
}

.previewContainer > .Preview > img {
    width: 100%;
    height: 100%;
}

.previewContainer .closeBtn {
    position: absolute;
    color: var(--red);
    background: transparent;
    font-size: 20px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    float: right;
    top: 10px;
    right: 14px;
}

.previewContainer .Close:hover {
    opacity: 0.8;
    transition: 200ms;
}

@media (max-width: 420px) {
    .UploadedReports {
        background: transparent;
        padding: 10px 0px 10px 0px;
        margin: 10px 0 -20px 0;
        border-radius: 10px;
    }

    .UploadedReports > h2 {
        margin-bottom: 2em;
        font-size: 1.2em;
    }

    .UploadedReports > p {
        font-size: 12px;
        position: relative;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .UploadedReports .Header {
        justify-content: center;
    }

    .container {
        padding: 20px 30px 30px 30px;
    }

    .files {
        grid-template-columns: repeat(1, minmax(0, 2fr));
        margin: 0;
        grid-gap: 10px;
    }

    .files > div > svg {
        font-size: 1.6em;
    }
    .files > div > p {
        font-size: 12px;
        margin-bottom: 4em;
    }

    .btnContainer > button {
        padding: 8px 20px;
        font-size: 10px;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .overlay {
        background: rgba(0, 0, 0, 0.5);
    }

    .previewContainer > .Preview {
        position: relative;
        top: 6%;
        left: 6%;
        width: 88%;
        height: 440px;
    }
}
