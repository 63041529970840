.header {
    position: relative;
    color: var(--white);
    border-radius: 10px 10px 0 0;
    display: grid;
    grid-template-columns: minmax(0, 75%) minmax(0, 25%);
    min-height: 200px;
}

.header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-image: linear-gradient(to top, var(--light-grey), transparent);
    z-index: 10;
}

.header div {
    position: relative;
    z-index: 50;
}

.headLeftWrapper {
    position: relative;
    top: 70px;
    left: 40px;
    padding-right: 24em;
}

.headLeftWrapper > div {
    position: absolute;
    top: 0;
    left: 0;
}
.headLeftWrapper > h3 {
    position: absolute;
    margin: 0;
    top: 4px;
    left: 170px;
    font-weight: 500;
    color: var(--dark);
    font-size: 18px;
}
.headLeftWrapper > p {
    position: relative;
    margin: 0;
    top: 40px;
    left: 170px;
    font-size: 12px;
    color: var(--dark);
}

.headLeftWrapper > p:last-child {
    margin-top: 2px;
    font-weight: 500;
}

.img {
    position: absolute;
    object-fit: cover;
    object-position: 48% 10%;
    height: 140px;
    width: 140px;
    border-radius: 10px;
    border: 4px solid var(--white);
    box-shadow: 0 3px 5px var(--grey2);
    display: block;
    margin: 0 auto;
}

.flex {
    margin-top: -3em;
    margin-left: -8em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-right: 4em;
}

.flex > div {
    text-align: center;
    margin-top: 2em;
    margin-bottom: -1em;
}

.flex > div > p:nth-child(1) {
    font-size: 14px;
    color: var(--dark);
    font-weight: 500;
}

.flex > div > p:nth-child(2) {
    margin-top: -24px;
    color: var(--primary);
    font-size: 2.1em;
    font-weight: 500;
    margin-bottom: 0;
}

.flex > div > p:nth-child(2) > span {
    font-size: 12px;
    margin-top: -10px;
}

.header div > button {
    font-weight: 500;
    border-radius: 20px;
    border: none;
    background-color: var(--primary);
    color: var(--white);
    font-size: 14px;
    padding: 10px 20px;
    display: block;
    margin-top: -110px;
}

.header div > button:hover {
    opacity: 0.9;
    cursor: pointer;
    transition: 200ms;
}

@media (max-width: 420px) {
    .header {
        position: relative;
        color: var(--white);
        border-radius: 10px 10px 0 0;
        display: grid;
        grid-template-columns: minmax(0, 100%);
        min-height: 200px;
        margin: 0 -6px;
        margin-bottom: 4px;
        z-index: 1;
    }

    .headLeftWrapper {
        top: 10px;
        left: 10px;
        padding-right: 8em;
    }

    .headLeftWrapper > h3 {
        position: absolute;
        margin: 0;
        top: 4px;
        left: 100px;
        font-weight: 500;
        color: var(--dark);
        font-size: 14px;
    }
    .headLeftWrapper > p {
        position: relative;
        margin: 0;
        top: 30px;
        left: 100px;
        font-size: 10px;
        color: var(--dark);
    }

    .headLeftWrapper > p:last-child {
        margin-top: 6px;
        font-weight: 500;
    }

    .img {
        height: 90px;
        width: 90px;
        border: 2px solid var(--white);
    }

    .flex {
        position: relative;
        margin-top: 6em;
        margin-left: 4em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-right: 4em;
    }

    .flex > div {
        padding: 0;
    }

    .flex > div > p:nth-child(1) {
        font-size: 11px;
        color: var(--dark);
        font-weight: 500;
        opacity: 0.9;
    }

    .flex > div > p:nth-child(2) {
        color: var(--primary);
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .flex .consult {
        margin-top: -56px;
        margin-bottom: 20px;
    }

    .flex > div > p:nth-child(2) > span {
        font-size: 10px;
        margin-top: -10px;
    }

    .header div > button {
        position: absolute;

        font-weight: 500;
        border-radius: 20px;
        border: none;
        background-color: transparent;
        color: var(--primary);
        font-size: 10px;
        padding: 6px 2px;
    }
}
