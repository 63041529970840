.view {
    width: 100%;
}

.view > p {
    color: rgb(149, 143, 134);
    margin: 0;
    font-size: 14px;
}

.box {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
