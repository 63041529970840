.join {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
}

.box {
    padding: 20px;
}
