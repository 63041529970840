.control {
    background-color: rgb(42, 37, 37);
    position: absolute;
    width: 100%;
    height: 90px;
    bottom: 0;
    z-index: 1;
    margin: 0 auto;
}

.control > div {
    display: flex;
    gap: 60px;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
}

.control > div > p {
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.control > div > p:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 250ms;
}

.control > div > p:nth-child(1) {
}

.control > div > p:nth-child(2) {
}

.control > div > p:nth-child(3) {
    background-color: rgb(145, 14, 14);
    border: 1px solid rgb(145, 14, 14);
}

.control > div > .audio:nth-child(1) {
}

.control > div > .video:nth-child(2) {
}

.control > div > p > svg {
    display: block;
    font-size: 0.7em;
}
