.order {
    display: grid;
    grid-template-columns: 80% 20%;
    padding: 20px 10px 10px 20px;
    margin-top: 10px;
}

.search {
    position: fixed;
    margin-top: 10px;
    right: 0;
    height: 100vh;
    width: 16%;
    border-top-left-radius: 80px;
    background-color: var(--white);
}

.search > div {
    margin-top: 60px;
    padding: 10px;
}

.search input[type='text'] {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 2px solid var(--light);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.35);
    font-size: 12px;
    color: var(--dark-grey);
    outline: none;
    cursor: pointer;
}

.search input[type='text']:focus {
    border: 2px solid var(--grey2);
}

@media (max-width: 420px) {
    .order {
        grid-template-columns: 100%;
        padding: 0px;
        margin: 10px;
        margin-top: 30px;
    }

    .search {
        display: none;
    }
}
