.TransparentNav {
    position: absolute;
    z-index: 500;
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.TransparentNav h4 {
    display: inline-block;
    padding-top: 0;
    padding-left: 0px;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
    color: var(--dark);
}
.TransparentNav h4 > a {
    text-decoration: none;
    color: var(--dark);
}
.TransparentNav h4 span {
    color: var(--primary);
}
.TransparentNav ul {
    list-style-type: none;
    float: right;
    padding-right: 10px;
}
.TransparentNav ul li {
    float: left;
    margin: 0 0 0 25px;
    transition: 200ms;
}
.TransparentNav ul li a {
    text-decoration: none;
    color: var(--primary);
    font-size: 14px;
    font-weight: 600;
    transition: 200ms;
}
.TransparentNav ul li a:hover {
    color: var(--secondary);
}
.TransparentNav ul li span {
    text-decoration: none;
    color: var(--primary);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 420px) {
    .TransparentNav h4 {
        font-size: 14px;
        margin-top: 2.1em;
    }

    .TransparentNav ul {
        padding-right: 2px;
    }
    .TransparentNav ul li {
        margin: 10px 0 0 12px;
    }
    .TransparentNav ul li a {
        font-size: 12px;
    }
    .TransparentNav ul li span {
        font-size: 12px;
    }
}
