.changePP > .button {
    background: var(--light);
    border-radius: 50%;
    border: none;
    color: var(--secondary);
    position: relative;
    /* bottom: 30px;
    left: 160px; */
}

.changePP > .button:hover {
    cursor: pointer;
    color: var(--red);
}

.container,
.overlay {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100vh;
    z-index: 10;
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
}

.imgUploader {
    position: relative;
    top: 14%;
    left: 34%;
    width: 34%;
    height: 480px;
    padding: 20px;
    background: var(--white);
    color: var(--dark-blue);
    text-align: center;
    border-radius: 10px;
    z-index: 12;
}

.imgUploader h2 {
    color: var(--dark);
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

.imgUploader > .Close {
    cursor: pointer;
    background: none;
    border: 1px solid var(--red);
    border-radius: 40px;
    color: var(--red);
    position: absolute;
    font-weight: 1000;
    right: 16px;
    top: 14px;
}

.imgUploader > .Close:hover {
    background: var(--red);
    color: var(--white);
    transition: 300ms;
}

.imgUploader > .uploadForm {
    width: 100%;
    background: var(--light);
}

.uploadForm > .SelectedImg {
    height: 230px;
}

.uploadForm > .SelectedImg div .PreviewContainer {
    width: 100%;
    background: none;
}

.uploadForm > .SelectedImg div > .PreviewContainer > .ImgPreview {
    object-fit: cover;
    height: 200px;
    width: 200px;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    padding: 10px;
}

.uploadForm > .controlBtn {
    border-top: 1px solid var(--dark-blue);
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.controlBtn > button {
    cursor: pointer;
    margin: 10px;
}

.controlBtn > .Select {
    border: 1px solid var(--secondary);
    border-radius: 2px;
    background: none;
    color: var(--secondary);
    padding: 5px;
}

.controlBtn > .Select:hover {
    border: 1px solid var(--secondary);
    background: var(--secondary);
    color: var(--white);
    transition: 300ms;
}

.controlBtn > .Select span {
    margin-left: 5px;
}

.controlBtn > .Upload {
    border: 1px solid var(--green);
    border-radius: 2px;
    background: none;
    color: var(--green);
    padding: 5px;
}

.controlBtn > .Upload:hover {
    border: 1px solid var(--green);
    background: var(--green);
    color: var(--white);
    transition: 300ms;
}

.controlBtn > .Upload span {
    margin-left: 5px;
}

.Upload {
    border: none;
    background: var(--green);
    color: var(--white);
    padding: 6px 20px;
    border-radius: 4px;
    margin-top: 30px;
}

.Upload:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 300ms;
}

.Upload > span {
    padding-left: 4px;
    text-transform: uppercase;
}

@media (max-width: 420px) {
    .container,
    .overlay {
        position: fixed;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 200vh;
        z-index: 10;
    }

    .overlay {
        background: rgba(0, 0, 0, 0.2);
    }

    .imgUploader {
        position: relative;
        top: 4%;
        left: 6%;
        width: 88%;
        height: 440px;
        padding: 20px;
        background: var(--white);
        color: var(--dark-blue);
        text-align: center;
        border-radius: 10px;
        z-index: 12;
    }

    .imgUploader h2 {
        color: var(--dark);
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }

    .imgUploader > .Close {
        cursor: pointer;
        background: none;
        border: 1px solid var(--red);
        border-radius: 40px;
        color: var(--red);
        position: absolute;
        font-weight: 1000;
        right: 16px;
        top: 14px;
    }

    .Upload {
        margin-top: 20px;
        font-size: 12px;
    }
}
