@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
:root {
    --primary: #457dc3;
    --secondary: #4885cf;
    --secondary2: #41add9;
    --secondary3: #5d8aa8;
    --light-secondary: #3ecbcf;
    --dark: #333;
    --dark-blue: #23395d;
    --dark-grey: #555;
    --grey: #7987a1;
    --grey2: #aebacf;
    --light-grey: #dee2e9;
    --light: #f0f0f0;
    --light-low: #f8f8f8;
    --white: #fff;
    --green: #54b881;
    --deep-red: #b43b3b;
    --red: #e23f3f;
    --yellow: #fdfd96;
    --orange: #ff9d5c;
    --light-low: #f8f8f8;
    --low-skyblue: #e6e6fa;
    --lower-blue: #b0c4de;
}
* {
    font-family: Poppins;
}
body {
    margin: 0;
    background-color: var(--light-low);
}

/* ::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-thumb {
    background: rgb(171, 171, 171);
    border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
    background: rgb(151, 151, 151);
} */

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
