.Dropdown {
    position: relative;
}

.Dropdown > .DropdownButton {
    border: none;
    background: none;
    color: var(--primary);
    position: relative;
    margin-left: 16em;
    margin-top: -10px;
    font-size: 12px;
}

.Dropdown > .DropdownButton:hover {
    background: var(--light);
    cursor: pointer;
    border-radius: 4px;
}

.Dropdown > .DropdownContent > {
    position: absolute;
    padding: 15px;
    background: var(--light);
    border: 1px solid var(--light);
}

.Dropdown > .DropdownContent > .DropdownItem:hover {
    cursor: pointer;
    color: var(--dark-blue);
    background: var(--light);
}

.formPopup,
.overlay {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100vh;
    z-index: 10;
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
}

.formPopup > .reqInfo {
    position: absolute;
    left: 40%;
    top: 34%;
    width: 350px;
    height: 150px;
    border-radius: 10px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 10;
}

.reqInfo > p {
    color: var(--dark);
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}

.reqInfo .Content {
    padding: 0 20px;
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
}

.reqInfo > .Content > button {
    cursor: pointer;
    border: none;
    color: var(--white);
    display: inline-block;
    padding: 8px 24px;
    border-radius: 4px;
    margin: 20px 30px;
    font-size: 12px;
}
.reqInfo > .Content > button:hover {
    box-shadow: 1px 3px 10px var(--grey2);
}

.reqInfo > .Content > .Button1 {
    background: var(--primary);
}

.reqInfo > .Content > .Button2 {
    background: var(--red);
}

@media (max-width: 420px) {
    .formPopup,
    .overlay {
        position: fixed;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100vh;
        z-index: 10;
    }

    .overlay {
        background: rgba(0, 0, 0, 0.2);
    }

    .formPopup > .reqInfo {
        position: absolute;
        left: 6%;
        top: 30%;
        width: 88%;
        height: 150px;
        border-radius: 10px;
        background: var(--white);
        display: flex;
        flex-direction: column;
        padding: 20px;
        z-index: 10;
    }

    .reqInfo > p {
        color: var(--dark);
        display: inline-block;
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
    }

    .reqInfo > .Content > button {
        cursor: pointer;
        border: none;
        color: var(--white);
        display: inline-block;
        padding: 6px 20px;
        border-radius: 4px;
        margin: 20px 30px;
        font-size: 12px;
    }
}
