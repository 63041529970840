.Detail {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 50%));
    gap: 10px;
    padding: 10vh 15vw;
    margin-top: -20px;
}

.imgDiv > div:nth-child(1) {
    border-radius: 4px;
}

.Detail > div:nth-child(2) h2 {
    margin: 0 0 0 20px;
    color: var(--primary);
    font-size: 20px;
    font-weight: 400;
}

.Detail > div:nth-child(2) p {
    margin: 10px 0 0 20px;
    color: var(--dark-grey);
    text-align: justify;
    font-size: 14px;
}
.Detail > div > h3 {
    margin: 24px 0 0 20px;
    color: var(--primary);
    font-weight: 400;
    font-size: 20px;
    margin-bottom: -10px;
}
.Detail > div > ul {
    list-style-type: none;
    padding: 0 0 0 20px;
}
.Detail > div > ul > li {
    color: var(--dark-grey);
    font-size: 16px;
}
.Detail > div > ul > li > svg {
    color: var(--primary);
    margin-top: 5px;
    margin-right: 10px;
}

@media (max-width: 420px) {
    .Detail {
        grid-template-columns: repeat(1, minmax(0, 100%));
    }

    .Detail > div:nth-child(2) h2 {
        margin: 10px 0px;
        font-weight: 400;
        font-size: 22px;
    }

    .Detail > div > h3 {
        margin: 10px 0px;
        font-weight: 400;
        font-size: 22px;
        margin-top: 1em;
    }

    .Detail > div:nth-child(2) p {
        margin: 0px;
        text-align: justify;
        font-size: 14px;
    }
    .Detail > div > ul {
        list-style-type: none;
        padding: 0 0 0 2px;
        margin-top: -2px;
        font-size: 14px;
    }
}
