.calenderWrapper {
    background: transparent;
    z-index: 1;
    position: relative;
    width: 100%;
    margin: auto;
    padding: 10px 0 20px;
    border-radius: 10px;
    overflow: hidden;
}

.container {
    width: 90%;
    margin: auto;
}

/* .header .container span {
    color: var(--dark);
    font-size: 20px;
    font-weight: 600;
}
.header .container h1 {
    margin-top: 5px;
    color: var(--grey);
    font-size: 13px;
    font-weight: 300;
}
.header .container .menuToggle {
    width: 7px;
    height: 6px;
    background: var(--white);
    padding: 20px;
    border-radius: 50%;
    direction: rtl;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 15px var(--light-grey);
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
.header .container .menuToggle span {
    display: block;
    width: 25px;
    height: 2px;
    background: var(--grey);
    border-radius: 2px;
    transition: all 300ms ease;
}
.header .container .menuToggle span:not(:last-child) {
    margin-bottom: 5px;
}
.header .container .menuToggle span:first-child {
    width: 20px;
}
.header .container .menuToggle span:last-child {
    width: 15px;
}
.header .container .menuToggle:hover span:first-child,
.header .container .menuToggle:hover span:last-child {
    width: 100%;
} */

.todayBox {
    background-image: linear-gradient(#93a7c5, #21527d);
    color: var(--white);
    padding: 20px 40px;
    position: relative;
    box-shadow: 0px 0px 10px -9px var(--secondary);
    margin-bottom: 50px;
    border-radius: 4px;
    margin-top: -10px;
}
.todayBox::before {
    content: '';
    background-image: linear-gradient(30deg, #93a7c5, #21527d);
    opacity: 0.1;
    z-index: -1;
    display: block;
    width: 100%;
    height: 40px;
    margin: auto;
    position: absolute;
    bottom: -13px;
    left: 50%;
    transform: translatex(-50%);
    border-radius: 50%;
    /* box-shadow: 0px 0px 20px 0 var(--secondary); */
}
.todayBox .breadcrumb {
    position: relative;
    color: var(--white);
    font-size: 12px;
}
.todayBox .breadcrumb::after {
    content: '>';
    vertical-align: middle;
    font-size: 12px;
    display: inline-block;
    color: var(--white);
    text-align: center;
    position: absolute;
    left: 42px;
    top: 1px;
}
.todayBox .dateTitle {
    font-size: 20px;
    margin: 7px 0 0 0;
    letter-spacing: 1px;
    font-weight: 500;
    color: var(--white);
    font-size: 14px;
}
.todayBox .plusIcon {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    cursor: pointer;
    transition: all 350ms ease;
    font-size: 1.4em;
    color: var(--white);
}
.todayBox .plusIcon:hover {
    transform: translateY(-40%);
}
.todayBox .plusIcon:active {
    top: 52%;
    transform: translatey(-52%);
    right: 38px;
}

.upcomingEvents .container .appointment {
    color: var(--dark);
    font-size: 12px;
    margin-top: -1em;
    margin-bottom: 30px;
    position: relative;
    opacity: 0.8;
    font-weight: 500;
}
.upcomingEvents .container .appointment::before {
    content: '';
    display: block;
    width: 30%;
    height: 2px;
    background-color: var(--light-grey);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}

.upcomingEvents .container .eventWrapper {
    margin-bottom: 30px;
}
.upcomingEvents .container .eventWrapper .event {
    position: relative;
    margin-bottom: 2em;
    padding-left: 30px;
    cursor: pointer;
    border-bottom: 1px solid var(--light-grey);
}
.upcomingEvents .container .eventWrapper .event .i {
    font-size: 1em;
    font-weight: 100;
    position: absolute;
    left: 0;
    top: 3px;
}
.upcomingEvents .container .eventWrapper .event:nth-child(1) {
    color: var(--red);
    opacity: 0.9;
}
.upcomingEvents .container .eventWrapper .event:nth-child(2) {
    color: var(--orange);
}
.upcomingEvents .container .eventWrapper .event:nth-child(1) {
    color: var(--red);
}
.upcomingEvents .container .eventWrapper .event:nth-child(3) {
    color: var(--green);
}
.upcomingEvents .container .eventWrapper .event .eventTime {
    margin: 0;
    color: var(--dark);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    opacity: 0.8;
}
.upcomingEvents .container .eventWrapper .event .eventTimeLeft {
    position: absolute;
    top: 5px;
    right: 0;
    color: var(--grey);
    font-size: 10px;
    font-weight: 800;
    font-style: italic;
}
.upcomingEvents .container .eventWrapper .event .eventDescription {
    margin-top: 10px;
    color: var(--dark);
    font-size: 12px;
    font-weight: 300;
}

.eventButton {
    display: flex;
    align-items: center;
    margin-left: auto;
    border: 0;
    padding: 0;
    background: linear-gradient(90deg, var(--secondary) 0%, var(--secondary) 100%);
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 3px 3px 15px var(--light-grey);
}
.eventButton:active {
    position: relative;
    top: 2px;
    left: 2px;
}
.eventButton .eventButtonTitle {
    color: var(--white);
    padding: 2px 12px 2px 14px;
    font-size: 14px;
    font-weight: 500;
}

.eventButton .eventButtonTitle:hover {
    opacity: 0.8;
    transition: 200ms;
}

.eventButton .eventButtonIcon {
    display: inline-block;
    padding: 0 12px 0 12px;
}

@media (max-width: 420px) {
    .upcomingEvents .container h3::before {
        width: 42%;
    }
}
