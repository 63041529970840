.wrapper,
.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    left: 0;
    top: 0;
}

.overlay {
    background: rgb(0, 0, 0, 0.1);
    z-index: 1;
}

.sidebar {
    width: 15%;
    height: 100vh;
    margin: 0;
    position: fixed;
    display: none;
    z-index: 100;
    top: 0;
    left: 0;
}

.container {
    color: var(--white);
    padding-left: 0px;
    height: 100vh;
    background-image: linear-gradient(30deg, #93a7c5, #21527d);
    /* background-color: var(--white); */
    border-top-right-radius: 80px;
    position: relative;
}

.container > p {
    margin-left: 20px;
    color: var(--white);
}

.container a {
    font-size: 12px;
    color: var(--white);
    display: block;
    padding: 12px;
    padding-left: 20px;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.container a > i:hover,
.container a > span:hover {
    opacity: 0.7;
}

.container a i {
    margin-right: 5px;
}

.container a svg {
    font-size: 18px;
    color: var(--white);
}

/* .container a:nth-child(2) > svg {
    color: var(--primary);
}
.container a:nth-child(3) > svg {
    color: var(--secondary2);
}
.container a:nth-child(4) > svg {
    color: var(--green);
}
.container a:nth-child(5) > svg {
    color: var(--red);
}
.container a:nth-child(6) > svg {
    color: var(--light-secondary);
}
.container a:nth-child(7) > svg {
    color: var(--orange);
}
.container a:nth-child(8) > svg {
    color: var(--secondary);
} */

.container a > span {
    padding-left: 12px;
    font-size: 12px;
}

.container > .call {
    position: absolute;
    bottom: 8px;
}

.call > a {
    border: none;
    background-color: transparent;
    color: var(--white);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
}

/* active */
.container .active {
    color: var(--primary);
    outline: none;
    position: relative;
    background-color: var(--light-low);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.container .active::after {
    content: '';
    position: absolute;
    background-color: transparent;
    bottom: 100%;
    right: 0;
    height: 35px;
    width: 35px;
    border-bottom-right-radius: 18px;
    box-shadow: 0 20px 0 0 var(--light-low);
}

.container .active::before {
    content: '';
    position: absolute;
    background-color: transparent;
    top: 100%;
    right: 0;
    height: 35px;
    width: 35px;
    border-top-right-radius: 18px;
    box-shadow: 0 -20px 0 0 var(--light-low);
}

.container .active > span:hover {
    opacity: 1;
}

.container p {
    margin: 0;
    padding: 40px 0 30px 0;
}

.marginIcon1 {
    font-size: 14px;
}
.marginIcon2 {
    margin-left: 1px;
    font-size: 12px;
}
.marginIcon3 {
    margin-left: 2px;
    font-size: 14px;
}
.textMargin1 {
    padding-left: 1px;
}
.textMargin2 {
    padding-left: 10px;
}
.textMargin3 {
    padding-left: 2px;
}

.sidebar .setting {
    width: 93%;
    position: absolute;
    bottom: 70px;
}

@media (max-width: 420px) {
    .sidebar {
        width: 62%;
        height: 100vh;
        margin: 0;
        display: block;
    }

    .container > p {
        margin-left: 20px;
        font-size: 14px;
    }

    .textMargin2 {
        margin-left: 4px;
    }
}
