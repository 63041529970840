.Wrapper {
    margin-top: 30px;
    padding: 10px 0px 20px 20px;
}
.Wrapper > div {
    background: var(--white);
    margin: 20px 20px 0 0;
    border-radius: 10px;
    box-shadow: 3px 3px 15px var(--light-grey);
    padding: 40px 20px 20px 20px;
}
.Nav {
    width: 100%;
    border-bottom: 2px solid var(--secondary);
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    position: relative;
}
.Nav > span {
    position: relative;
    bottom: 7.8px;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0;
    border: 2px solid var(--secondary);
    border-bottom: none;
    font-size: 12px;
    cursor: pointer;
    margin: 0 0 0 10px;
}
.deactiveNav {
    background: var(--white);
    color: var(--dark-blue);
}
.activeNav {
    background: var(--secondary);
    color: var(--white);
}

@media (max-width: 420px) {
    .Wrapper {
        padding: 0;
        margin: 0;
        padding-left: 20px;
        margin-top: 60px;
        margin-bottom: 120px;
    }
    .Nav {
        padding: 0px 10px 0 0px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
    .Nav > span {
        text-align: center;
        padding: 10px 10px;
        font-size: 12px;
        margin: 0 0 0px 0px;
    }
}
