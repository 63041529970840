.DoubleNumber {
    background: var(--white);
    border-radius: 10px;
    padding: 0px 10px;
    position: relative;
}

.header > p {
    color: var(--primary);
    text-align: center;
    margin: 0;
    margin-bottom: -10px;
    font-size: 16px;
}

.header > p:hover {
    transition: 300ms;
    cursor: pointer;
    opacity: 0.8;
}

.field form > div {
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.field .divRef {
    font-size: 12px;
    color: var(--dark);
    opacity: 0.9;
    margin-bottom: -2px;
}

.field .divRef > p {
    margin-left: -40px;
    padding-right: 60px;
}

.field .divRef > li {
    padding: 0 20px;
}

.field input {
    appearance: none;
    border: none;
    outline: 0;
    background: var(--light-low);
    margin: 6px;
    padding: 8px;
    width: 300px;
    border-radius: 4px;
    font-size: 10px;
}

.field form > div > span {
    color: var(--dark);
    font-size: 10px;
    opacity: 0.8;
}

.field form > div > button {
    border: none;
    color: var(--white);
    background: var(--primary);
    padding: 6px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10px;
    margin-left: -30px;
    margin-top: 10px;
}

@media (max-width: 420px) {
    .DoubleNumber {
        padding: 10px 14px;
        margin: -20px 0;
    }
    .field input {
        padding: 6px 12px;
        min-width: 8em;
        max-width: 10em;
        font-size: 12px;
    }
    .field input + span {
        font-size: 12px;
    }

    .field input::placeholder {
        opacity: 0.8;
        font-size: 10px;
    }

    .field form > button {
        padding: 6px 12px;
        font-size: 12px;
    }

    .field form > div {
        padding: 0 24px;
    }

    .field .divRef {
        font-size: 10px;
        margin-bottom: -2px;
        margin-left: -10px;
    }

    .field .divRef > p {
        margin-left: -20px;
        padding-right: 10px;
    }

    .field .divRef > li {
        padding: 0 4px;
    }
}
