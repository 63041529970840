.footer {
    position: relative;
    height: 18em;
}

.footer > .loveImg {
    position: absolute;
    z-index: 5;
    height: 7em;
    margin-top: 6.5em;
    right: 6.8em;
    opacity: 0.9;
}

.footer > .footerImg1 {
    width: 100%;
    height: 17em;
    position: absolute;
    transform: scaleX(-1);
    background-color: var(--white);
    opacity: 0.1;
}

.footer > .footerImg2 {
    width: 100%;
    height: 17em;
    position: absolute;
    opacity: 0.1;
}

.footer > div {
    position: absolute;
    margin-top: 8.2%;
    left: 7%;
    text-align: center;
}

.footer > div > p {
    font-size: 1.2em;
    font-weight: 500;
    color: var(--primary);
}

.footer > div > div {
    font-size: 1em;
    text-align: center;
    margin-top: -14px;
}

.footer > div > div > img {
    height: 20px;
    cursor: pointer;
    padding: 0 10px;
    margin-bottom: -10px;
}

.footer > div > div > img:hover {
    opacity: 0.7;
}

.footer > div > span {
    color: var(--primary);
    font-size: 10px;
    text-align: center;
    display: block;
    margin-top: 10px;
}

@media (max-width: 420px) {
    .footer {
        position: relative;
        height: 11em;
        margin-bottom: -20px;
    }

    .footer > .loveImg {
        position: absolute;
        z-index: 5;
        height: 4.8em;
        margin-top: 4.2em;
        right: 0px;
    }

    .footer > .footerImg1 {
        width: 100%;
        height: 11em;
        position: absolute;
        transform: scaleX(-1);
        opacity: 0.9;
        object-fit: cover;
    }

    .footer > div {
        position: absolute;
        margin-top: 20%;
        left: 1%;
        text-align: center;
        bottom: 6px;
    }

    .footer > div > p {
        font-size: 0.9em;
        font-weight: 400;
        color: var(--white);
        margin-bottom: 0px;
    }

    .footer > div > div {
        font-size: 0.8em;
        text-align: center;
        margin-top: 0em;
    }

    .footer > div > div > img {
        height: 14px;
        cursor: pointer;
        padding: 0 10px;
        margin: -6px 0;
    }

    .footer > div > p {
        font-size: 12px;
        font-weight: 500;
    }

    .footer > div > span {
        color: var(--white);
        font-size: 8px;
        text-align: center;
        display: block;
        margin-top: 10px;
    }
}
