.offer {
    width: 100%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 10px 40px;
    position: relative;
}

.offer div > p:nth-child(1) {
    font-size: 18px;
    color: var(--dark);
    font-weight: 600;
    margin-top: 4px;
    opacity: 0.9;
}

.offer div > p:nth-child(1) > span {
    color: var(--green);
    font-size: 22px;
    font-weight: 600;
}

.offer div > p:nth-child(2) {
    font-size: 14px;
    color: var(--dark);
    margin-top: -14px;
    margin-left: 40px;
}

.offer img {
    position: absolute;
    right: 4em;
    width: 6.6rem;
    height: 5.6rem;
    border-radius: 20px;
    border-radius: 10px;
}

@media (max-width: 420px) {
    .offer {
        width: 100%;
        background-color: var(--white);
        border-radius: 10px;
        padding: 4px 20px;
        position: relative;
    }

    .offer div > p:nth-child(1) {
        font-size: 12px;
        color: var(--dark);
        font-weight: 600;
        margin-top: 4px;
        opacity: 0.9;
    }

    .offer div > p:nth-child(1) > span {
        color: var(--green);
        font-size: 14px;
        font-weight: 600;
    }

    .offer div > p:nth-child(2) {
        font-size: 10px;
        color: var(--dark);
        margin-top: -14px;
        margin-left: 10px;
        padding-right: 80px;
    }

    .offer img {
        position: absolute;
        right: 0.9em;
        bottom: 4px;
        width: 3.2rem;
        height: 3rem;
        border-radius: 10px;
    }
}
