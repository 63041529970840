.tableList {
    width: 100%;
}

.tableMain {
    border-collapse: separate;
    border-spacing: 0 4px;
    display: table;
    width: 100%;
}

.tableMain tr {
    box-shadow: 0 2px 3px rgb(228, 232, 233);
}

.tableMain tr th {
    font-size: 12px;
    padding: 12px;
    background-color: var(--primary);
    border-radius: 4px;
    color: var(--white);
}

.tableMain tr td {
    text-align: center;
    font-size: 12px;
    color: var(--dark);
    padding: 10px;
    background-color: whitesmoke;
    border-radius: 4px;
}

.tableMain button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: rgb(18, 150, 136);
}

.tableMain .cap {
    text-transform: uppercase;
}

@media (max-width: 420px) {
    table {
        margin-top: 10px;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    thead tr {
        display: none;
    }

    tr {
        margin: 0 0 1rem 0;
    }

    .tableMain tr td {
        padding-left: 100px;
    }
}
