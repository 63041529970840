.wrapper {
    margin-top: 20px;
    display: none;
}

.wrapper > p {
    color: var(--dark);
    font-size: 14px;
    margin-bottom: 0px;
    position: relative;
}

.wrapper > p::before {
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: var(--grey2);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}

.container {
    background-color: var(--white);
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 20px 20px;
    border-radius: 10px;
}

.gridWrapper {
    display: grid;
    grid-template-columns: 2.6fr 0.8fr 1.6fr 2.8fr 0.6fr;
    box-shadow: 2px 4px 8px var(--light);
    border-radius: 10px;
    border: 1px solid var(--light);
    padding: 6px 30px;
    margin-bottom: 20px;
    background-color: var(--white);
}

.gridWrapper:hover {
    box-shadow: 4px 8px 12px var(--light);
}

.gridWrapper > p {
    font-size: 12px;
    color: var(--dark);
    text-transform: capitalize;
}

.gridWrapper button {
    border-radius: 4px;
    border: none;
    padding: 4px 10px;
    margin: 12px 0;
    background-color: var(--secondary2);
    color: var(--white);
    font-size: 10px;
}

.gridWrapper button:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 200ms;
}

.gridWrapper > p > .icon {
    font-size: 20px;
    color: var(--secondary2);
}

.gridWrapper > p:nth-child(1) {
    margin-top: 8px;
}

.gridWrapper > p:nth-child(3),
.gridWrapper > p:nth-child(4) {
    text-align: center;
}

.gridWrapper > p:nth-child(3) {
    margin-left: 20px;
}

.gridWrapper > p:nth-child(4) {
    font-size: 10px;
    margin-left: -14px;
    margin-top: 16px;
}

.gridWrapper > p:last-child {
    text-align: right;
}

.gridWrapper > p > span {
    margin-left: 1em;
}

.gridWrapper > p > span:hover {
    color: var(--secondary);
}

/* status */
.wrapper p.status span {
    position: relative;
    border-radius: 30px;
    padding: 6px 14px 6px 25px;
}

.wrapper p.status span:after {
    position: absolute;
    top: 9px;
    left: 10px;
    width: 10px;
    height: 10px;
    content: '';
    border-radius: 50%;
}

.wrapper p.status .active {
    background: #cff6dd;
    color: #1fa750;
}
.wrapper p.status .active:after {
    background: #23bd5a;
}

.wrapper p.status .waiting {
    background: #fdf5dd;
    color: #cfa00c;
}

.wrapper p.status .waiting:after {
    background: #f2be1d;
}

.wrapper p.status .progress {
    background: #ddf8fd;
    color: #0cb2cf;
}

.wrapper p.status .progress:after {
    background: #1dcff2;
}

.wrapper p.status .running {
    background: #ddf1fd;
    color: #0c84cf;
}

.wrapper p.status .running:after {
    background: #1da0f2;
}

.wrapper p.status .cancel {
    background: #d1d1d1;
    color: #595252;
}
.wrapper p.status .cancel:after {
    background: #535050;
}

.wrapper p.status .delete {
    background: #fde8dd;
    color: #d61111;
}
.wrapper p.status .delete:after {
    background: #b81111;
}

@media (max-width: 420px) {
    .wrapper {
        margin-top: 20px;
        margin-bottom: 80px;
    }

    .container {
        background-color: transparent;
        margin-top: -12px;
        margin-bottom: 20px;
        padding: 20px 0px;
        border-radius: 10px;
    }

    .gridWrapper {
        grid-template-columns: 1fr;
        margin-bottom: 20px;
        background-color: var(--white);
        text-align: center;
    }

    .gridWrapper > p:nth-child(1) {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .gridWrapper > p:nth-child(3) {
        margin-left: 0px;
        margin-top: -10px;
    }

    .gridWrapper button {
        margin-top: 20px;
    }
}
