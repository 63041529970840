.userDetail {
    width: 95%;
    padding: 10px 0px;
    margin: 20px auto;
    background-color: var(--white);
    border-radius: 10px;
    margin-bottom: 10px;
}

.userDetail .formWrap .sectionHeader {
    color: var(--secondary);
    margin-bottom: 10px;
    font-size: 14px;
}

.userDetail .formWrap .formGrid1 {
    padding: 30px;
    background: var(--light-low);
    border-radius: 6px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

.userDetail .formWrap .formGrid {
    padding: 30px;
    background: var(--light-low);
    border-radius: 6px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}

.userDetail label {
    display: block;
    font-size: 12px;
    color: var(--dark-grey);
    margin-bottom: 14px;
    font-weight: 450;
}

.userDetail input,
.userDetail select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 2px solid var(--white);
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.35);
    font-size: 12px;
    color: var(--dark-grey);
    background: var(--white);
    outline: none;
}

.userDetail input:focus {
    border: 2px solid var(--grey2);
}

.userDetail .Button {
    background-color: var(--secondary);
    padding: 8px 20px;
    color: var(--white);
    font-size: 12px;
    overflow: hidden;
    transition: 0.5s;
    margin: 0 auto;
    letter-spacing: 1px;
    border: none;
    border-radius: 4px;
    width: 12em;
    display: block;
    margin-top: 1em;
    cursor: pointer;
    margin-bottom: -10px;
}

.userDetail .Button:hover {
    opacity: 0.8;
    cursor: pointer;
    transition: 200ms;
}

.alertMessage {
    text-align: center;
    color: var(--red);
    font-weight: 500;
    font-size: 14px;
    margin-top: 16px;
}

@media (max-width: 420px) {
    .userDetail {
        margin-bottom: -10px;
    }

    .userDetail .formWrap .formGrid1 {
        padding: 20px;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
    }

    .userDetail .formWrap .formGrid {
        padding: 20px;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
    }
}
