.wrapper {
    margin-top: 1em;
    padding: 10px 10em;
    margin-bottom: 4em;
}

.title {
    display: flex;
    justify-content: center;
}

.title h1 {
    font-weight: 500;
    padding: 0 40px;
    margin-bottom: 40px;
    font-size: 30px;
    color: var(--dark-blue);
}

.grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 4px 2em;
}

.grid > div {
    padding: 0 2em;
    border-radius: 10px;
    transition: 250ms;
    border: none;
}

.grid > div:hover {
    background-color: var(--white);
    box-shadow: 2px 4px 6px var(--light);
    cursor: pointer;
    transition: 250ms;
    transform: translateY(-4px);
}

.grid > div:nth-child(even) {
    display: flex;
    justify-content: flex-end;
}

.grid > div:nth-child(even) > p > div {
    text-align: right;
}

.grid > div > p > div > div > span {
    display: block;
    font-size: 14px;
    color: var(--dark);
    padding: 4px 0;
}

.grid div > p {
    display: flex;
}

.grid div > p > img {
    width: 280px;
    height: 220px;
    object-fit: cover;
    border-radius: 10px;
}

.grid div > p > div {
    padding-left: 20px;
}

.grid div:nth-child(even) > p > div {
    padding-right: 20px;
}

.grid div > p > div > h2 {
    font-size: 24px;
    color: var(--primary);
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 6px;
}

.grid div > p > div > span {
    color: var(--dark);
    font-size: 14px;
}

.grid div > p > div > div {
    margin-top: 14px;
}

.grid div > p > div > div > span {
    font-size: 14px;
    color: var(--dark);
    padding: 2px 0px;
}

@media (max-width: 420px) {
    .wrapper {
        margin-top: 1em;
        padding: 10px 20px;
        margin-bottom: 4em;
    }

    .title h1 {
        font-weight: 500;
        padding: 0 6px;
        margin-bottom: 10px;
        font-size: 18px;
        color: var(--dark);
        margin-top: -10px;
    }

    .grid > div {
        padding: 0px;
    }

    .grid div > p {
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
    }

    .grid div:nth-child(even) > p {
        flex-direction: column-reverse;
        justify-content: center;
    }

    .grid div > p > img {
        display: block;
        margin: 0 auto;
        width: 80vw;
        height: 160px;
        object-fit: cover;
        border-radius: 10px;
    }

    .grid div > p > div,
    .grid div:nth-child(even) > p > div {
        padding-left: 0px;
        text-align: center;
    }

    .grid div:nth-child(even) > p > div {
        padding-right: 0px;
    }

    .grid div > p > div > h2 {
        font-size: 18px;
    }

    .grid div > p > div > span {
        color: var(--dark);
        font-size: 12px;
    }

    .grid div > p > div > div {
        margin-top: 12px;
    }

    .grid div > p > div > div > span {
        font-size: 12px;
        color: var(--dark);
        padding: 2px 0px;
    }
}
