.sidebar {
    background: var(--light);
    height: 100vh;
    position: fixed;
    box-shadow: 0px 2px 4px var(--light);
    width: 20%;
}
.header {
    position: relative;
    padding: 70px 0 20px 0;
}
.header::after {
    content: '';
    position: absolute;
    left: 10%;
    bottom: 0;
    height: 1px;
    width: 80%;
    background: var(--light);
}
.header > a {
    text-align: center;
    text-decoration: none;
    color: var(--dark-blue);
}
.header > a > img {
    margin: 10px auto;
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;
    opacity: 0.6;
    transition: 200ms;
}
.header > a > h2 {
    color: var(--dark-blue);
    font-weight: 700;
}
.header a > h2 > span {
    color: var(--primary);
}

.header > a:hover > img {
    transform: scale(0.9);
}

.sidebar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin: 20px 0 0 0;
}
.sidebar ul > li {
    border-left: 6px solid var(--white);
    font-size: 14px;
    font-weight: 500;
}
.sidebar ul > li > a > span {
    margin-left: 10px;
}
.sidebar ul > li > span > span {
    margin-left: 10px;
}
.sidebar ul > li:hover {
    border-left: 6px solid var(--light);
}
.sidebar ul > li:hover > a,
.sidebar ul > li:hover > span {
    cursor: pointer;
    color: var(--dark-blue);
    background-image: linear-gradient(to right, var(--white), var(--light));
}
.sidebar ul > li.active {
    border-left: 6px solid var(--primary);
}
.sidebar ul > li.active > a,
.sidebar ul > li.active > span {
    background-image: linear-gradient(to right, var(--white), var(--light));
}
.sidebar ul > li > a,
.sidebar ul > li > span {
    text-decoration: none;
    display: block;
    padding: 10px 50px;
    color: var(--primary);
}
.secondUl {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 40px;
}
.secondUl::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10%;
    width: 80%;
    height: 1px;
    background: var(--light);
}
.marginIcon1 {
    font-size: 14px;
}
.marginIcon2 {
    margin-left: 1px;
    font-size: 14px;
}
.marginIcon3 {
    margin-left: 2px;
    font-size: 16px;
}
.textMargin1 {
    padding-left: 1px;
}
.textMargin2 {
    padding-left: 3px;
}
.textMargin3 {
    padding-left: 2px;
}

@media (max-width: 420px) {
    .sidebar {
        background: var(--white);
        width: 12%;
    }

    .header {
        position: relative;
        padding: 70px 0 20px 0;
    }
    .header > a > img {
        margin: 0px auto;
        width: 20px;
        height: 20px;
        margin-top: -3em;
    }
    .header::after {
        content: '';
        position: absolute;
        left: 10%;
        bottom: 2em;
    }
    .header > a > h2 {
        display: none;
    }
    .sidebar ul {
        margin-top: -1em;
    }
    .sidebar ul > li {
        font-size: 1em;
    }
    .sidebar ul > li > a,
    .sidebar ul > li > span {
        padding: 8px 10px;
    }
    .sidebar ul > li > a > span {
        display: none;
    }
    .sidebar .logout {
        display: none;
    }
    .marginIcon1 {
        font-size: 16px;
    }
    .marginIcon2 {
        margin-left: 1px;
        font-size: 18px;
    }
    .marginIcon3 {
        margin-left: 2px;
        font-size: 18px;
    }
    .sidebar ul > li:hover {
        border-left: 2px solid var(--light);
    }
    .sidebar ul > li.active {
        border-left: 4px solid var(--primary);
        padding-left: 2px;
    }
}

@media (max-width: 390px) {
    .sidebar ul > li > a,
    .sidebar ul > li > span {
        padding: 8px 5px;
    }
}
