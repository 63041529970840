.content {
    width: 70%;
    padding: 0;
    margin: 0 auto;
    margin-top: 10px;
}

.content .logo {
    text-align: center;
    font-weight: 500;
    padding: 0 40px;
    margin-bottom: 40px;
    font-size: 30px;
    color: var(--dark-blue);
}

.question {
    position: relative;
    background: var(--white);
    padding: 20px 50px;
    margin-bottom: 1em;
    border-radius: 10px;
    display: block;
    width: 100%;
    cursor: pointer;
    border: 1px solid var(--light);
    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 400;
}

.question:hover {
    box-shadow: 2px 4px 6px var(--light-low);
    transition: 300ms;
}

.answers {
    padding: 2px 15px;
    margin: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    z-index: -1;
    position: relative;
    opacity: 0;
    color: var(--dark);
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    text-align: justify;
    line-height: 1.6;
    font-size: 14px;
    opacity: 0.9;
}

.questions:checked ~ .answers {
    height: auto;
    opacity: 1;
    padding: 0px 30px 40px 30px;
    margin-bottom: 0em;
    color: var(--dark);
    z-index: 1;
}

.content > div > div {
    position: relative;
}

.plus {
    position: absolute;
    right: 0;
    margin-right: 40px;
    margin-top: 24px;
    z-index: 5;
    font-size: 16px;
    color: var(--primary);
    line-height: 100%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}

.questions:checked ~ .plus {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.questions {
    display: none;
}

@media (max-width: 420px) {
    .content {
        width: 100%;
        margin: 0 auto;
        margin-top: 4em;
        margin-bottom: -20px;
    }

    .content .logo {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 1.2em;
    }

    .question {
        padding: 20px 30px 20px 20px;
        border-radius: 10px;
        width: 92%;
        font-size: 12px;
        font-weight: 400;
        margin: 0 auto;
        margin-bottom: 1em;
    }

    .answers {
        padding: 0px 10px;
        margin: 4px 0;
        line-height: 1.6;
        font-size: 12px;
        width: 100%;
    }

    .questions:checked ~ .answers {
        padding: 0px 40px;
        margin-bottom: 2.4em;
        font-size: 12px;
    }

    .plus {
        top: 0;
        right: 0;
        margin-right: 24px;
    }
}
