.home {
    width: 100%;
}
.wrapper {
    display: grid;
    grid-template-columns: 73% 27%;
    gap: 20px;
    padding: 60px 10px 30px 20px;
}

.info {
    width: 22%;
    position: fixed;
    right: 0;
    top: 0;
    margin-top: 60px;
}

.info > div {
    background-color: var(--white);
    border-top-left-radius: 80px;
    height: 100vh;
}

@media (max-width: 420px) {
    .home {
        margin-top: 40px;
        margin-bottom: 6em;
        padding: 10px;
    }

    .wrapper {
        grid-template-columns: 100%;
        gap: 0px;
        padding: 0;
    }

    .info {
        display: none;
    }
}
