.success {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    min-height: 100vh;
    height: 100%;
}

.success > div {
    width: 50%;
}

.success > div > img {
    width: 60%;
    display: block;
    margin: 0 auto;
}

.details > h1 {
    color: var(--green);
    font-size: 40px;
    margin-bottom: 2px;
}

.details > p {
    margin: 0;
    font-size: 14px;
    color: var(--dark);
}

.details > p > span {
    font-size: 16px;
    font-weight: 600;
}

.details > a {
    display: inline-block;
    margin-top: 50px;
    padding: 10px 20px;
    background-color: var(--secondary);
    color: var(--white);
    border-radius: 4px;
}

.details > a:hover {
    opacity: 0.9;
    transition: 200ms;
}

@media (max-width: 440px) {
    .success {
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
    }

    .success > div {
        width: 100%;
    }

    .success > div > img {
        width: 70%;
        margin-top: 4em;
    }

    .details {
        text-align: center;
    }

    .details > h1 {
        color: var(--green);
        font-size: 28px;
    }

    .details > a {
        margin-top: 36px;
        font-size: 14px;
    }
}
