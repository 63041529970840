.sidebar {
    width: 15%;
    height: 100vh;
    margin: 0;
    position: fixed;
    margin-top: 40px;
}

.container {
    color: var(--white);
    padding-left: 20px;
    height: 100vh;
    background-image: linear-gradient(30deg, #93a7c5, #21527d);
    border-top-right-radius: 80px;
}

.container a {
    font-size: 12px;
    color: var(--white);
    display: block;
    padding: 12px;
    padding-left: 20px;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.container a > i:hover,
.container a > span:hover {
    opacity: 0.7;
}

.container a i {
    margin-right: 5px;
}

.container a > span {
    padding-left: 12px;
}

/* active */
.container .active {
    color: var(--primary);
    outline: none;
    position: relative;
    background-color: var(--light-low);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.container .active::after {
    content: '';
    position: absolute;
    background-color: transparent;
    bottom: 100%;
    right: 0;
    height: 35px;
    width: 35px;
    border-bottom-right-radius: 18px;
    box-shadow: 0 20px 0 0 var(--light-low);
}

.container .active::before {
    content: '';
    position: absolute;
    background-color: transparent;
    top: 100%;
    right: 0;
    height: 35px;
    width: 35px;
    border-top-right-radius: 18px;
    box-shadow: 0 -20px 0 0 var(--light-low);
}

.container .active > span:hover {
    opacity: 1;
}

.container p {
    margin: 0;
    padding: 40px 0 30px 0;
}

.marginIcon1 {
    font-size: 14px;
}
.marginIcon2 {
    margin-left: 1px;
    font-size: 12px;
}
.marginIcon3 {
    margin-left: 2px;
    font-size: 14px;
}
.textMargin1 {
    padding-left: 1px;
}
.textMargin2 {
    padding-left: 3px;
}
.textMargin3 {
    padding-left: 2px;
}

.sidebar .setting {
    width: 93%;
    position: absolute;
    bottom: 70px;
}

@media (max-width: 420px) {
    .sidebar {
        display: none;
    }
}
