.wrapper {
    margin-top: 5em;
    margin-bottom: 6em;
}

.title {
    display: flex;
    justify-content: center;
}

.title h1 {
    font-weight: 500;
    padding: 0 40px;
    margin-bottom: 40px;
    font-size: 30px;
    color: var(--dark-blue);
}

.gridBox {
    margin: 0 auto;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3em;
}

.gridBox div {
    border: 1px solid var(--light);
    transition: 200ms;
    padding: 1em 4em 2em;
    border-radius: 10px;
}

.gridBox div:hover {
    transform: translateY(-6px);
    transition: 200ms;
    cursor: pointer;
    box-shadow: 2px 4px 6px var(--light);
}

.gridBox div > p {
    display: flex;
    align-items: center;
    margin-top: 0px;
}

.gridBox div > p > span {
    padding-right: 20px;
    font-size: 28px;
}

.gridBox div:nth-child(1) > p > span {
    color: var(--red);
}
.gridBox div:nth-child(2) > p > span {
    color: var(--green);
}
.gridBox div:nth-child(3) > p > span {
    color: var(--orange);
}
.gridBox div:nth-child(4) > p > span {
    color: var(--deep-red);
}

.gridBox div > p > p {
    font-size: 20px;
    color: var(--dark-blue);
    font-weight: 500;
}

.gridBox div > span {
    font-size: 14px;
    color: var(--dark);
    display: block;
    margin-top: -20px;
    text-align: justify;
}

@media (max-width: 420px) {
    .title h1 {
        padding: 0 6px;
        margin-bottom: 30px;
        font-size: 18px;
        color: var(--dark);
        margin-top: -30px;
    }

    .gridBox {
        margin: 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1em;
        padding: 0px 20px;
    }

    .gridBox div {
        border: 1px solid var(--light);
        transition: 200ms;
        padding: 1em 1.2em 2em;
        border-radius: 10px;
    }

    .gridBox div > p > span {
        padding-right: 14px;
        font-size: 20px;
        display: block;
    }

    .gridBox div > p > p {
        font-size: 14px;
        color: var(--dark-blue);
        font-weight: 500;
    }

    .gridBox div > span {
        font-size: 12px;
        margin-top: -20px;
        text-align: justify;
    }
}
