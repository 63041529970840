.wrapper {
    margin-top: -17px;
}

.wrapper > p {
    color: var(--dark);
    font-size: 14px;
    position: relative;
    margin-bottom: 10px;
}

.wrapper > p::before {
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: var(--grey2);
    position: absolute;
    top: 60%;
    transform: translatey(-60%);
    right: 0;
}

.ProfileCard {
    background: var(--white);
    padding: 40px 20px;
    border-radius: 10px;
    padding-bottom: 10px;
}
.ProfileCard > .PP {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    position: relative;
}

.ProfileCard > .PP > div {
    position: absolute;
    right: 14px;
    bottom: 50px;
}

.ProfileCard h6 {
    text-align: center;
    margin: -30px 0 0 0;
    color: var(--primary);
    font-size: 20px;
    font-weight: 500;
}
.ProfileCard > span {
    display: block;
    text-align: center;
    font-weight: 500px;
    margin: 5px 0 0 0;
    font-size: 12px;
}
.ProfileCard > span > span {
    color: var(--dark-grey);
    font-weight: 300;
}

.ProfileCard > span:nth-child(4) {
    display: block;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 500;
    margin-top: 16px;
    color: var(--dark);
    border: 1px solid var(--light-low);
    box-shadow: 2px 4px 10px var(--light);
    width: 80%;
    padding: 10px;
    border-radius: 10px;
}
.ProfileCard > p:nth-child(4) > span {
    color: var(--green);
    font-weight: 600;
    font-size: 1.2em;
}

.ProfileCard .bloodGrp {
    margin-left: 2px;
}

.ProfileCard > p:nth-child(5) {
    margin: 20px 0 0 0;
}

.ProfileCard > div {
    margin-top: 30px;
}

.ProfileCard > div > p {
    text-align: left;
    font-size: 12px;
    position: relative;
    color: var(--dark);
}

.ProfileCard > div > p > span {
    position: absolute;
    left: 120px;
    color: var(--dark-grey);
    font-size: 12px;
    font-weight: 500;
}
.ProfileCard > div > p > span::before {
    content: ': ';
}

.ProfileImage {
    object-fit: cover;
    height: 150px;
    width: 150px;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
}

@media (max-width: 420px) {
    .wrapper {
        margin-top: 0px;
    }

    .wrapper > p {
        font-size: 12px;
    }

    .ProfileCard {
        padding: 20px 20px;
    }

    .ProfileCard > div:nth-child(1) {
        margin: 0 auto;
        width: 120px;
        height: 120px;
    }

    .ProfileCard > .PP > div {
        position: absolute;
        right: -10px;
        bottom: -2px;
    }

    .ProfileCard > h6 {
        font-size: 20px;
        margin-top: 20px;
    }

    .ProfileCard > p {
        font-size: 12px;
        margin-top: 0px;
    }

    .ProfileImage {
        height: 120px;
        width: 120px;
        margin: 0 auto;
    }
}
