.layout {
    margin: 0;
    padding: 0;
}

.container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 15% 85%;
}

@media (max-width: 420px) {
    .container {
        grid-template-columns: 100%;
    }
}
