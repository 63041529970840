.Wrapper {
    margin-top: 40px;
    display: grid;
    grid-template-columns: minmax(0, 30%) minmax(0, 70%);
    gap: 20px;
    padding: 20px;
}

@media (max-width: 420px) {
    .Profile {
        grid-template-columns: repeat(1, 100%);
        padding: 10px;
        margin-top: 40px;
        margin-bottom: 80px;
    }
    .Wrapper {
        display: grid;
        grid-template-columns: repeat(1, 100%);
        padding: 0;
        margin: 0;
    }
}
