.wraper {
    height: 100vh;
    position: relative;
    background-color: var(--white);
}

.wraper > a {
    position: absolute;
    top: 30px;
    left: 34px;
}

.wraper > a > img {
    height: 48px;
    width: 48px;
    transition: 300ms;
}

.wraper > a > img:hover {
    cursor: pointer;
    opacity: 0.9;
    transform: scale(1.1);
    transition: 300ms;
}

.box {
    height: 96vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 5em;
}

.box > div:nth-child(1) {
    min-width: 32em;
}

.box > div > img {
    width: 32em;
    height: 32em;
    object-fit: cover;
    border-radius: 10px;
}

.box > div > h2 {
    font-size: 70px;
    line-height: 76px;
    color: var(--dark-blue);
    margin: 0;
}

.box > div > p {
    margin: 0;
    margin-top: 10px;
    padding-left: 4px;
    color: var(--dark-blue);
}

.box > div > p > span {
    color: var(--red);
}

.box > div > span {
    display: block;
    color: var(--red);
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    min-height: 24px;
    font-weight: 500;
}

.box > div > form {
    overflow: hidden;
    position: relative;
}

.box > div > form > svg {
    position: absolute;
    top: 40px;
    right: 10px;
    color: var(--grey);
    font-size: 14px;
    border-bottom: none;
}

.box > div > form > svg:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 200ms;
}

.box > div > form > input {
    margin-top: 30px;
    border: none;
    outline: 0;
    width: 100%;
    padding: 8px 0px;
    padding-left: 4px;
    border-bottom: 2px solid var(--light-grey);
}

.box > div > form > div {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.box > div > form > div > label {
    color: var(--dark);
    font-size: 14px;
}

.box > div > form > div > input {
    cursor: pointer;
}

.box > div > form > button {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    border: none;
    padding: 8px 30px;
    background-color: var(--primary);
    color: var(--white);
    border-radius: 4px;
    font-size: 14px;
}

.box > div > form > button:hover {
    cursor: pointer;
    opacity: 0.9;
    transition: 200ms;
}

@media (max-width: 420px) {
    .wraper > a {
        top: 20px;
        left: 20px;
    }

    .wraper > a > img {
        height: 34px;
        width: 34px;
    }

    .box {
        height: 100vh;
        padding: 0 2em;
        overflow-y: auto;
        align-items: flex-start;
    }

    .box > div:nth-child(1) {
        display: none;
    }

    .box > div {
        margin-top: 5em;
    }

    .box > div > h2 {
        font-size: 44px;
        line-height: 54px;
    }

    .box > div > p {
        font-size: 14px;
        margin-top: 6px;
    }
}
