.wrapper {
    margin: 0;
    padding: 2em 0em;
}

.container {
    padding: 0em 10em;
    padding-top: 2.6em;
}

.wrapper .infoWrapper {
    background-color: var(--white);
    border-radius: 0 0 10px 10px;
}

.wrapper .info {
    display: flex;
    justify-content: space-around;
    padding: 1em 10em 2em 10em;
}

.info > div {
    text-align: center;
}

.info > div > p {
    font-size: 12px;
    font-weight: 400;
    color: var(--dark);
    border-left: 1px solid var(--secondary);
    padding-left: 6px;
}

.info > div > span {
    display: block;
    margin-top: -4px;
    font-size: 14px;
    color: var(--dark);
    font-weight: 500;
}

.detailsWrapper {
    margin-top: 1em;
    padding-bottom: 1em;
    background-color: var(--white);
    border-radius: 10px;
}
.detailsWrapper > div {
    padding: 40px 20px 20px 20px;
}

.nav {
    border-bottom: 2px solid var(--secondary);
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
}

.nav span {
    position: relative;
    bottom: 8px;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    margin: 0 0 0 10px;
    border-bottom: none;
}

.nav span:hover {
    opacity: 0.9;
    transition: 300ms;
}

.activeNav {
    background: var(--primary);
    color: var(--white);
}
.deactiveNav {
    background: var(--white);
    color: var(--dark-blue);
}

.mobileIcon {
    display: none;
}

@media (max-width: 420px) {
    .wrapper {
        margin-bottom: 4em;
    }

    .container {
        padding: 0em 1em;
        padding-top: 2.6em;
    }
    .wrapper .infoWrapper {
        padding: 0px 20px 10px 30px;
        border-radius: 10px;
    }

    .wrapper .info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px 30px;
        padding: 1em 0em;
        position: relative;
    }

    .info > div > p {
        padding-left: 2px;
        font-size: 10px;
    }

    .info > div > span {
        font-size: 12px;
    }

    .detailsWrapper {
        background-color: transparent;
        margin: -10px;
    }
    .detailsWrapper > div > div:last-child {
        padding: 30px 10px;
        background-color: var(--white);
        margin-top: 10px;
        border-radius: 10px;
        margin: 0px -10px;
    }

    .nav {
        display: grid;
        grid-template-columns: repeat(8, 200px);
        grid-gap: 1px;
        overflow-x: auto;
        padding: 0;
        margin: -10px -20px 10px -20px;
    }

    .nav span {
        bottom: 0px;
        padding: 10px 10px 10px 18px;
        font-size: 12px;
    }

    .mobileIcon {
        display: block;
        position: absolute;
        right: -4px;
        bottom: 6px;
        font-size: 34px;
        color: var(--light-grey);
        opacity: 0.7;
    }
}
