.wrapper {
    padding: 0;
    margin: 0;
    padding-top: 30px;
}

.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding: 30px;
    margin-top: 10px;
}

.containerFlex {
    background-color: var(--white);
    border-radius: 10px;
    /* min-height: 180px; */
    box-shadow: 0px 4px 6px var(--light);
}

.containerFlex > .flex {
}

.flex > div:nth-child(2) {
}

.containerFlex > div:last-child {
    padding: 0 10px;
}

.containerFlex > .flex img {
    object-fit: cover;
    /* position: relative; */
    overflow: hidden;
    width: 100%;
    /* height: 20%; */
    border-radius: 10px 10px 2px 2px;
    /* border: 1px solid var(--green); */
}

.containerFlex > .flex > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
}

.containerFlex > .flex > div > p:nth-child(1) {
    font-size: 18px;
    font-weight: 600;
    color: var(--primary);
    padding-bottom: 2px;
    box-shadow: 0px 1px 2px var(--light);
    border-radius: 10px;
    padding: 2px 4px;
}

.containerFlex > .flex > div > p > span {
    font-size: 12px;
    color: var(--green);
}

.containerFlex > .flex > div > p:nth-child(2) {
    font-weight: 500;
}

.containerFlex > div:nth-child(2) {
    border: 2px solid var(--light);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-top: 12px;
    padding-bottom: 8px;
    min-height: 110px;
}

.containerFlex > div h6 {
    color: var(--primary);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

.containerFlex > div p {
    color: var(--dark);
    font-size: 12px;
    margin: 2px;
    text-align: center;
}

.containerFlex > div:nth-child(3) {
    /* border: 1px solid var(--green); */
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerFlex > div:nth-child(3) > button {
    /* position: absolute; */
    display: block;
    /* margin: 30px auto; */
    margin-bottom: 25px;
    margin-top: 20px;
    border: none;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    border-radius: 4px;
    padding: 8px 20px;
    transition: 300ms;
    width: 40%;
}

.containerFlex > button:hover {
    transform: translateY(-2px);
    cursor: pointer;
    opacity: 0.9;
    transition: 300ms;
}

.imgDiv {
    height: 160px;
    margin-bottom: 10px;

    /* border: 1px solid red; */
}

.plan {
    margin-top: 14px;
    /* border: 1px solid var(--red); */
}

@media (max-width: 420px) {
    .container {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
        padding: 20px;
        margin-top: 10px;
        margin-bottom: 8em;
    }

    .imgDiv {
    }

    .containerFlex {
        background-color: var(--white);
        border-radius: 10px;
        box-shadow: 0px 4px 6px var(--light);
    }

    .containerFlex > div img {
        display: block;
        object-fit: cover;
        position: relative;
        overflow: hidden;
        max-width: 100%;
        height: 40%;
        /* border: 1px solid var(--red); */
        margin: 0 auto;
        border-radius: 10px 10px 2px 2px;
    }

    .containerFlex > div h6 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .containerFlex > div:nth-child(2) > p {
        font-size: 10px;
    }

    .containerFlex > .flex img {
        object-fit: cover;
        position: relative;
        /* overflow: hidden; */
        width: 90%;
        height: 90%;
        margin-left: 14px;
        border-radius: 10px 10px 2px 2px;
    }

    .containerFlex > .flex > div {
        display: block;
        margin: 0 auto;
        margin-left: -14px;
        margin-top: 10px;
    }

    .containerFlex > .flex > div > p:nth-child(1) {
        font-size: 16px;
        font-weight: 600;
        color: var(--primary);
        padding-bottom: 2px;
        box-shadow: 0px 1px 2px var(--light);
        border-radius: 10px;
        padding: 2px 4px;
    }

    .containerFlex > .flex > div > p > span {
        font-size: 10px;
        color: var(--green);
    }

    .containerFlex > .flex > div > p:nth-child(2) {
        font-weight: 500;
        margin-top: 4px;
        opacity: 0.9;
    }

    .containerFlex > button {
        font-size: 10px;
        margin-bottom: 20px;
    }
}
