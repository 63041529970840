.Services {
    position: relative;
    z-index: 500;
    margin-top: -40px;
    margin-left: 8vw;
    margin-right: 8vw;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 25%));
    gap: 20px;
}
.Services > div {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--light);
    background-color: var(--white);
    padding: 20px 16px;
    border-radius: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    transition: 300ms;
}

.Services > div:hover {
    transform: translateY(-10px);
    transition: 300ms;
    cursor: pointer;
}

.Services > div > p {
    margin-top: 6px;
}

.Services > div > p:nth-child(1) {
    color: var(--dark);
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    opacity: 0.9;
}

.Services div > p > svg {
    font-size: 30px;
    margin-right: 12px;
    color: var(--dark-blue);
}

.Services div:nth-child(1) > p > svg {
    color: var(--green);
}
.Services div:nth-child(2) > p > svg {
    color: var(--orange);
}
.Services div:nth-child(3) > p > svg {
    color: var(--secondary);
}
.Services div:nth-child(4) > p > svg {
    color: var(--red);
}

@media (max-width: 420px) {
    .Services {
        grid-template-columns: repeat(1, minmax(0, 100%));
        margin-top: -2em;
    }
    .Services > div {
        box-shadow: 0 6px 12px var(--light);
        border-radius: 4px;
        padding: 10px 16px;
    }
    .Services > div > p:nth-child(1) {
        font-size: 14px;
    }

    .Services div > p > svg {
        font-size: 24px;
        margin-right: 12px;
        color: var(--dark-blue);
    }
}
