.wrapper {
    margin: 8em 0;
}

.grid {
    margin: 0 auto;
    width: 80%;
    display: grid;
    grid-template-columns: 1.5fr 2.4fr;
}

.flexBox {
    display: flex;
    flex-direction: column;
    padding-left: 3em;
}

.flexBox > p {
    font-size: 22px;
    color: var(--dark);
    font-weight: 500;
}

.flexBox > p > span {
    display: block;
    margin-left: 40px;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: -10px;
    color: var(--primary);
}

.flexBox > div > a > button {
    border: none;
    border-radius: 4px;
    color: var(--white);
    background-color: var(--dark-blue);
    padding: 8px 30px;
    font-size: 14px;
}

.flexBox > div > a > button:hover {
    opacity: 0.9;
    cursor: pointer;
    transition: 200ms;
}

.gridBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5em 8em;
}

.gridBox div {
    border-top: 2px solid var(--dark);
    transition: 200ms;
}

.gridBox div:hover {
    transform: translateY(-2px);
    transition: 200ms;
}

.gridBox div > p {
    display: flex;
    align-items: center;
    margin-top: 0px;
}

.gridBox div > p > span {
    padding-right: 20px;
    font-size: 24px;
}

.gridBox div:nth-child(1) > p > span {
    color: var(--orange);
}
.gridBox div:nth-child(2) > p > span {
    color: var(--deep-red);
}
.gridBox div:nth-child(3) > p > span {
    color: var(--primary);
}
.gridBox div:nth-child(4) > p > span {
    color: var(--secondary2);
}
.gridBox div:nth-child(5) > p > span {
    color: var(--red);
}
.gridBox div:nth-child(6) > p > span {
    color: var(--green);
}

.gridBox div > p > p {
    font-size: 18px;
    color: var(--dark-blue);
    font-weight: 500;
}

.gridBox div > span {
    font-size: 14px;
    color: var(--dark);
    display: block;
    margin-top: -20px;
    text-align: justify;
}

@media (max-width: 420px) {
    .wrapper {
        margin: 0;
        margin-top: -40px;
        padding: 0 20px;
    }

    .grid {
        margin: 0;
        width: 100%;
        grid-template-columns: 1fr;
    }

    .flexBox {
        flex-direction: column;
        padding-left: 1em;
    }

    .flexBox > p {
        font-size: 16px;
        color: var(--dark);
        font-weight: 500;
    }

    .flexBox > p > span {
        font-size: 20px;
    }

    .flexBox > div > a > button {
        font-size: 12px;
        display: block;
        margin: 0 auto;
    }

    .gridBox {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3em;
        margin-top: 30px;
    }

    .gridBox div > p > span {
        padding-right: 20px;
        font-size: 20px;
    }

    .gridBox div > p > p {
        font-size: 16px;
    }

    .gridBox div > span {
        font-size: 12px;
    }
}
